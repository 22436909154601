import styled, { css } from "styled-components"

/* container is flex */
export const Wrapper = styled.div`
  font-size: 1.2em;
  margin-left: 50px;
  margin-top: 5px;
`

export const Prefix = styled.div`
  font-size: 0.9em;
  color: slategray;
  margin-right: 0.3em;
`

export const EntryItem = styled.u`
  text-overflow: ellipsis;
  cursor: none;
  margin-right: 1em;
`
