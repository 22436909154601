import { Button } from "antd"
import styled from "styled-components"

export const ItemRow = styled.div<{ $isActive: boolean; $isInvalidated: boolean }>`
  ${({ $isActive }) => $isActive && "background-color:beige;"}
  ${({ $isInvalidated }) => $isInvalidated && "border: 1px solid orange;"}
  display: flex;
  align-items: center;
`

export const Footer = styled.div`
  margin-top: 5px;
  text-align: right;
`

export const Label = styled(Button).attrs({ type: "link", size: "small" })`
  font-family: monospace;
  width: 200px;
  text-align: left;
`

export const ButtonAttach = styled(Button).attrs({ size: "small" })`
  margin-top: 1em;
`
