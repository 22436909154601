import React, { FC, ReactNode } from "react"
import { Button } from "antd"
import { useHistory } from "react-router-dom"

export const AppLink: FC<{
  label?: string
  className?: string
  // custom function
  handleClick?: () => void
  // navigate to route
  route?: string
  // href Navigate to url
  href?: string
  disabled?: boolean
  title?: string
  children?: ReactNode
}> = ({ label, handleClick, route, href, disabled, children, title }) => {
  const history = useHistory()
  if (label === undefined && children === undefined) throw new Error("Neither label nor children!")

  if (handleClick === undefined && route === undefined && href === undefined) throw new Error("Needs either handleClick, route or href")
  if (handleClick !== undefined && route !== undefined) throw new Error("Only one arg for action")
  if (label === undefined && children === undefined) throw new Error("Needs either 'label' or children")

  return (
    <Button
      disabled={disabled}
      type="link"
      title={title}
      onClick={(_e) => (handleClick !== undefined ? handleClick() : href !== undefined ? window.open(href, "_blank") : history.push(route!))}
    >
      {label ?? children}
    </Button>
  )
}
