import React from "react"
import { PlusCircleTwoTone } from "@ant-design/icons"
import { useMutation } from "@apollo/client"
import { PopoverIcon } from "@app/lib/PopoverIcon"
import { SetEditEntry } from "@app/routes/userScreen/station/lib/SetEditEntry"
import { SetEditCategoryId } from "@app/routes/userScreen/station/lib/SetEditCategoryId"
import { Categories as WrapperCategories, Wrapper } from "@app/styles"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { CreateCategoryDocument, DeleteCategoryDocument, useStationCategorizedEntriesQuery, namedOperations } from "@app/generated"
import { Button, Empty } from "antd"
import { Category } from "../Category/Category"

type Props = {
  id: number
  setEditEntry: SetEditEntry
  setEditCategoryId: SetEditCategoryId
  canSelfEdit: boolean
}

const {
  Query: { StationCategorizedEntries }
} = namedOperations

export const Categories = Loadable(useStationCategorizedEntriesQuery)<Props>(
  ({
    setEditEntry,
    setEditCategoryId,
    canSelfEdit,
    data: {
      station: { id, categories }
    }
  }) => {
    const [createCategory] = useMutation(CreateCategoryDocument, {
      variables: { stationId: id },
      refetchQueries: [namedOperations.Query.StationCategorizedEntries]
    })

    const [_deleteCategory] = useMutation(DeleteCategoryDocument, { refetchQueries: [StationCategorizedEntries] })
    const deleteCategory = (id: number) => _deleteCategory({ variables: { id } })

    return (
      <Wrapper>
        <div className="subTitle">
          <PopoverIcon title="flokkaðir" content="Hlekkir í flokkum starfsstöðvar eru settir í birtingu eða notkun á starfsstöð." />
          {canSelfEdit && (
            <Button size="small" icon={<PlusCircleTwoTone />} onClick={() => createCategory()}>
              nýr efnisflokkur
            </Button>
          )}
        </div>

        {categories.length > 0 ? (
          <WrapperCategories>
            {categories.map((category) => (
              <Category
                key={category.id}
                category={category}
                setEditCategoryId={setEditCategoryId}
                setEditEntry={setEditEntry}
                deleteCategory={deleteCategory}
              />
            ))}
          </WrapperCategories>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null} />
        )}
      </Wrapper>
    )
  },
  ({ id }) => ({ id })
)
