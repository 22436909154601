import { IconInfoHover } from "@app/styles/IconInfoHover.styles"
import { Popover as AntPopover } from "antd"
import React, { FunctionComponent } from "react"

/**
 * Antd Popover but adds info Icon and styling
 */
export const PopoverIcon: FunctionComponent<{
  title?: string
  content: string
}> = ({ title, content }) => {
  return (
    <AntPopover content={content}>
      {typeof title === "string" ? (
        <span style={{ cursor: "help" }}>
          <span>{title}</span>
          <IconInfoHover />
        </span>
      ) : (
        <IconInfoHover $hasNoTitle />
      )}
    </AntPopover>
  )
}
