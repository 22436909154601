import { useMutation } from "@apollo/client"
import { StatefulInput } from "@app/lib/StatefulInput"
import { UpdateUserDocument, UpdateUserMutationVariables, useUserQuery } from "@app/generated"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Button, Checkbox, Col, Modal, Row, Select, Tooltip } from "antd"
import { Footer } from "./UserEditor.styles"
import { Label } from "@app/lib/Label/Label"

export const User = Loadable(useUserQuery)<{
  id: number
  handleCancel: () => void
}>(
  ({
    id: userId,
    data: {
      user: { isDisabled, name, email, ssn, globalRole },
      self: { isGlobalModerator },
      globalRoles
    },
    handleCancel
  }) => {
    const [_updateUser] = useMutation(UpdateUserDocument)
    const updateUser = (input: UpdateUserMutationVariables["input"]) => _updateUser({ variables: { id: userId, input } })

    const disableSubmit = !isGlobalModerator ? "ekki réttindi" : undefined

    return (
      <Modal
        visible
        onCancel={handleCancel}
        closable
        title={<span>{name}</span>}
        footer={
          <Footer>
            <Button onClick={handleCancel}>Loka</Button>
            <Tooltip title={disableSubmit} trigger="hover">
              <Button disabled={disableSubmit !== undefined} type="primary">
                Vista
              </Button>
            </Tooltip>
          </Footer>
        }
      >
        <Row align="middle" gutter={[2, 16]}>
          <Col span={7}>
            <Label title="kennitala">
              <StatefulInput initialValue={ssn} handleSubmit={(ssn) => updateUser({ ssn })} />
            </Label>
          </Col>

          <Col span={12}>
            <Label title="réttindi">
              <Select value={globalRole.id} onChange={(globalRoleId) => updateUser({ globalRoleId })}>
                {globalRoles.map(({ id, label }) => (
                  <Select.Option id={id} value={id}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Label>
          </Col>

          <Col span={5}>
            <Checkbox value={isDisabled} onChange={({ target: value }) => updateUser({ isDisabled: value.checked })} />
            óvirkjaður?
          </Col>
        </Row>

        <Row wrap gutter={[32, 16]}>
          <Col span={12}>
            <Label title="fullt nafn">
              <StatefulInput initialValue={name} handleSubmit={(name) => updateUser({ name })} />
            </Label>
          </Col>
          <Col span={12}>
            <Label title="netfang">
              <StatefulInput initialValue={email ?? ""} handleSubmit={(email) => updateUser({ email })} />
            </Label>
          </Col>
        </Row>
      </Modal>
    )
  },
  ({ id }) => ({ id })
)
