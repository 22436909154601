import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from "@apollo/client"
import { onError } from "@apollo/link-error"
import { getToken } from "@app/lib/auth"
import history from "./browserHistory"


const REACT_APP_API = process.env.REACT_APP_API || `${window.location.origin}/graphql`
const NODE_ENV = process.env.NODE_ENV

const isValidURL = (url: string) => {

  if ( ! /http/.test(url) ){
    return false
  }

  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}



if (!isValidURL(REACT_APP_API)) throw new Error(`missing REACT_APP_API or invalid, got '${REACT_APP_API}`)
const UNAUTHENTICATED = "UNAUTHENTICATED"


const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    if (NODE_ENV === "development") console.error("Appollo! ", graphQLErrors)

    // Check if one of the errors has UNAUTHENTICATED
    const userUnauthenticated = graphQLErrors.filter(({ extensions }) => extensions?.code === UNAUTHENTICATED)

    const isTokenInvalid = graphQLErrors.some((error) => {

      const extensions : object | any = error.extensions;
      const errorMessage = error.message;
      
      if ( extensions.code === "INTERNAL_SERVER_ERROR" ){
        return /Invalid token/.test(errorMessage)
      }
    })

    if (userUnauthenticated.length > 0 || isTokenInvalid) {
      localStorage.removeItem("token")
      history.push("/login")
      // We need to reload the page since apollo client.clearStore() dosen't
      // clear Apollos cache completely
      window.location.reload()
    }
  }

  return forward(operation)
})

const httpLink = new HttpLink({ uri: REACT_APP_API })

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken()
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  })
  return forward(operation)
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, errorLink as unknown as ApolloLink, httpLink])
})
