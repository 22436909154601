import React, { FC } from "react"
import { ActionableImpression } from "../ActionableImpression.enum"
import { actionableKindItems } from "../actionableKindItems"
import { ActionableKindItem } from "../ActionableKindItem"
import { ActionableKindId } from "../ActionableKindId.enum"
import {
  Empty,
  Wrapper,
  FixedPhone,
  Prefix,
  PreFixedPhone,
  MobileOrPager,
  PhoneGeneral,
  Vaktaskra,
  Link,
  LshInfo,
  UnspecifiedAction
} from "./ViewedActionable.styles"
import { Props } from "./Props"
import { triggerActionable } from "./triggerActionable"

// TODO remove
const actionableKindIds = Object.keys(ActionableKindId)

export const ViewedActionable: FC<Props> = ({ kindId, value, prefix }) => {
  let actionableItem: ActionableKindItem | null = null

  if (typeof kindId === "number") {
    const foundActionableItem = actionableKindItems.find(({ id }) => id === kindId)
    if (!foundActionableItem) return <div>.{kindId}.</div>

    actionableItem = foundActionableItem
  }

  if (typeof kindId === "string") {
    const foundActionableKindId = ActionableKindId[kindId as keyof typeof ActionableKindId] as unknown as ActionableKindId
    if (!foundActionableKindId) return <div>@{kindId}@</div>

    actionableItem = actionableKindItems.find(({ id }) => id === foundActionableKindId)!
  }

  if (actionableItem === null) return <div>?{kindId}?</div>

  const { impression, id } = actionableItem

  if (value === "") return <Empty>---</Empty>

  return (
    <Wrapper>
      {impression === ActionableImpression.FIXEDPHONE && (
        <FixedPhone>
          {prefix && <Prefix>{prefix}</Prefix>}
          <PreFixedPhone>{id === ActionableKindId.LSHFIXEDPHONE ? "543" : "432"}</PreFixedPhone>
          <span>{value}</span>
        </FixedPhone>
      )}

      {impression === ActionableImpression.MOBILEORPAGER && (
        <MobileOrPager onClick={(e) => triggerActionable({ e, value, kindId: id })}>
          {prefix && <Prefix>{prefix}</Prefix>}
          <span>{value}</span>
        </MobileOrPager>
      )}

      {impression === ActionableImpression.PHONEGENERAL && (
        <PhoneGeneral>
          {prefix && <Prefix>{prefix}</Prefix>}
          <span>{value}</span>
        </PhoneGeneral>
      )}

      {impression === ActionableImpression.LSHKIOSK && (
        <Vaktaskra onClick={(e) => triggerActionable({ e, value, kindId: id })}>
          {prefix && <Prefix>{prefix}</Prefix>}
          <Link>V</Link>
        </Vaktaskra>
      )}

      {impression === ActionableImpression.LSHINFO && (
        <LshInfo onClick={(e) => triggerActionable({ e, value, kindId: id })}>
          {prefix && <Prefix>{prefix}</Prefix>}
          <Link>L</Link>
        </LshInfo>
      )}

      {impression === ActionableImpression.UNSPECIFIEDACTION && (
        <UnspecifiedAction title={value} onClick={(e) => triggerActionable({ e, value, kindId: id })}>
          {prefix && <Prefix>{prefix}</Prefix>}
          <Link>{actionableItem.id === ActionableKindId.LINKTOACTIONABLE ? "🔗" : "..."}</Link>
        </UnspecifiedAction>
      )}
    </Wrapper>
  )
}
