import { HomeOutlined } from "@ant-design/icons"
import { Layout, Menu } from "antd"
import styled from "styled-components"

export const MenuItem = styled(Menu.Item)<{ $isActive?: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    `
      font-weight: bold;
    `}
`

export const Header = styled(Layout.Header)`
  background-color: #d5f5e3;
  z-index: 10;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid GRAY;
  padding: 0 12px;
  font-size: 1.2em;
`

export const UserArea = styled.div`
  flex: 0 1 auto;
  justify-content: right;
  > span {
    margin-right: 8px;
  }
`

export const NavigationMenu = styled(Menu)`
  background-color: inherit; /* remove default white */
  font-size: 1.2em;
`

export const IconHome = styled(HomeOutlined)`
  > svg {
    font-size: 1.5em;
  }
`

export const Content = styled(Layout.Content)`
  margin-top: 66px;
  padding: 0 16px;
  background-color: white;
`
