import styled, { css } from "styled-components"

export const Wrapper = styled.div<{ $isNotValidated: boolean }>`
  color: ${({ $isNotValidated }) => ($isNotValidated ? "orange" : "inherit")};
  display: flex;
  overflow: hidden; /* in case of very long title */
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 2px 0;
  &.canDrop {
    background-color: GREENYELLOW;
    &.isOver {
      border-style: ridge;
      background-color: yellow;
    }
  }
`

export const Prefix = styled.div`
  font-size: 0.9em;
  color: slategray;
  margin-right: 0.3em;
`

export const Link = styled.div<{ $isBorrowed?: boolean }>`
  flex-grow: 1;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: left;
  ${({ $isBorrowed }) =>
    $isBorrowed &&
    css`
      font-style: italic;
      opacity: 0.7;
    `}
`

export const Actionables = styled.div`
  text-align: right;
  float: right;
`
