import { EditEntryWrapper } from "@app/lib/EditEntryWrapper/EditEntryWrapper"
import { SetEditEntry } from "@app/routes/userScreen/station/lib/SetEditEntry"
import { dndTypes } from "@app/routes/userScreen/station/lib/dndTypes"
import { OtherStationCategorizedEntriesQuery } from "@app/generated"
import React, { FunctionComponent } from "react"
import { useDrag } from "react-dnd"

export const OtherStationEntry: FunctionComponent<{
  entry: OtherStationCategorizedEntriesQuery["station"]["categories"][0]["dingaloes"][0]["entry"]
  setEditEntry: SetEditEntry
}> = ({ entry, entry: { id: entryId }, setEditEntry }) => {
  // DRAG for pushing into Station Categories
  const [_, dragEntryRef] = useDrag(() => ({
    item: { type: dndTypes.OTHERSTATIONENTRY, entryId }
  }))

  return <EditEntryWrapper $ref={dragEntryRef} entry={entry} handleClick={() => setEditEntry(entryId, undefined)} />
}
