import { ActionableImpression } from "./ActionableImpression.enum"
import { ActionableKindId } from "./ActionableKindId.enum"
import { ActionableKindItem } from "./ActionableKindItem"

export const actionableKindItems: ActionableKindItem[] = [
  {
    id: ActionableKindId.LSHPAGER,
    isClickable: true,
    index: 2,
    label: "LSH Boðtæki",
    impression: ActionableImpression.MOBILEORPAGER,
    description: "Númer í boðtæki.",
    rxErrorDescription: "4 tölustafir. Hægt að opna síðu til að senda skilaboð á tæki.",
    actionBaseUrl: "http://160.210.16.252?msg=&bp=2&no={*}",
    rxValidate: `^((?!(0))[0-9]{4})$`
  },
  {
    id: ActionableKindId.LSHFIXEDPHONE,
    isClickable: false,
    label: "LSH borðsími",
    impression: ActionableImpression.FIXEDPHONE,
    description: "Borðsími Landspítala.",
    rxErrorDescription: "4 tölustafir. 543- er sett sjálfvirkt í forskeyti.",
    index: 4,
    rxValidate: `^((?!(0))[0-9]{4})$`
  },
  {
    id: ActionableKindId.HSUFIXEDPHONE,
    isClickable: false,
    label: "HSU borðsími",
    impression: ActionableImpression.FIXEDPHONE,
    description: "Borðsími HSU.",
    rxErrorDescription: "4 tölustafir. 432- er sett sjálfvirkt í forskeyti.",
    index: 4,
    rxValidate: `^((?!(0))[0-9]{4})$`
  },
  {
    id: ActionableKindId.MOBILE,
    isClickable: true,
    label: "Farsími",
    impression: ActionableImpression.MOBILEORPAGER,
    description: "Farsímanúmer (íslenskt).",
    rxErrorDescription: "7 tölustafir. Ef erlend númer nota þá 'Sími, annar'. Opnar sérstaka GSM síðu LSH þar sem hægt að senda SMS.",
    index: 6,
    actionBaseUrl: "http://gsm.lsh.is/?user=global&simi={*}",
    rxValidate: `^((?!(0))[0-9]{7})$`
  },
  {
    id: ActionableKindId.PHONEOTHER,
    isClickable: false,
    impression: ActionableImpression.PHONEGENERAL,
    label: "Annað símanúmer",
    description: "Símanúmer annað en GSM/borðsími en engin smellivirkni (þar sem ekki GSM númer).",
    rxErrorDescription: "7 tölustafir.",
    index: 8,
    rxValidate: `^((?!(0))[0-9]{4,7})$`
  },
  {
    id: ActionableKindId.GENERALURL,
    isClickable: true,
    impression: ActionableImpression.UNSPECIFIEDACTION,
    label: "Almenn vefslóð",
    description: "Frjáls vefslóð, fyrir vefsíður aðrar en skilgreindar hér í lista.",
    rxErrorDescription: "Tengill (URL), hafið https:// eða http:// fremst í slóðinni.",
    index: 80,
    actionBaseUrl: "{*}",
    rxValidate: `^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`
  },
  {
    id: ActionableKindId.GAEDAHANDBOK,
    isClickable: true,
    impression: ActionableImpression.LSHINFO,
    label: "LSH Gæðahandbók",
    description: "Síður úr Gæðahandbók með fasta slóð ('varanlegar').",
    rxErrorDescription: `Slóðin er allt það sem er á eftir 'gaedahandbaekur/'.
    Dæmi: slóðin er 'http://heitur/focal/Gaedahandbaekur/gnhskurda.nsf//0/00E4BAA093440FDE002581210055B0EC' þá er gildið 'gnhskurda.nsf//0/00E4BAA093440FDE002581210055B0EC'.
    Skoðið aðra hlekki til að sjá dæmi!`,
    index: 82,
    actionBaseUrl: "http://dominoapp2/focal/gaedahandbaekur/{*}",
    rxValidate: `^(gnhskurda|gnhSkurda|gnhLyfjaapo|gnh45bb|gnhLyfjaApo|gnhkvth|gnhsykla)\.nsf\/0?\/0?\/?[A-F0-9]*$`
  },
  {
    id: ActionableKindId.BRUNNUR,
    isClickable: true,
    impression: ActionableImpression.LSHINFO,
    label: "LSH innri vefur",
    description: "Sértækar síður af innra vef LSH (Brunnur). Sérstaklega flokkað til að fyrirbyggja tvískráningar.",
    rxErrorDescription: "Notið slóð í lok URLs, dæmi: 'http://innri.lsh.is/brunnurinn/*****slóð-er-hér*****'",
    index: 82,
    actionBaseUrl: "https://innri.lsh.is/brunnurinn/{*}",
    rxValidate: `(\w|\/)*`
  },
  {
    id: ActionableKindId.LSHINFO,
    isClickable: true,
    impression: ActionableImpression.LSHKIOSK,
    label: "LSH vaktþjónusta",
    description: "LSH vaktir: forskráðir vakta- eða þjónustulistar.",
    rxErrorDescription: "1-4 tölustafir (ID). Er fundin handvirkt úr slóð vaktalista/þjónustu.",
    index: 41,
    actionBaseUrl: "http://info.lsh.is/ContactGroupLookup.aspx?id={*}",
    rxValidate: `^([0-9]{1,4})$`
  },
  {
    id: ActionableKindId.GOOGLEDRIVE,
    isClickable: true,
    impression: ActionableImpression.UNSPECIFIEDACTION,
    label: "Google skjal",
    description: "Skjöl af hvaða tegund úr Google Drive (Docs, Spreadsheet, Presentation).",
    rxErrorDescription: "Valfrjáls slóð en verður að innihalda 'google.com'.",
    index: 82,
    // actionBaseUrl: "https://drive.google.com/file/d/{*}",
    actionBaseUrl: "{*}",
    // different for team Drive ID
    // rxValidate: `([\w-]){33}`
    rxValidate: `.*google.com.*$`
  },
  // {
  //   id: ActionableKind.35,
  // isClickable: true,
  //   impression: ActionableImpression.UNSPECIFIEDACTION,
  //   label: "OneDrive skjal",
  //   description: "Skjöl af Microsoft OneDrive/Teams.",
  //   rxError: "Vantar stuðning HUT (er í vinnslu).",
  //   index: 83,
  //   disabled: true,
  //   actionBaseUrl: "https://onedrive/document/{*}/preview",
  //   rxValidate: `^((?!(0))[0-9]{1,4})$`
  // },
  {
    id: ActionableKindId.YOUTUBE,
    isClickable: true,
    impression: ActionableImpression.UNSPECIFIEDACTION,
    label: "Youtube",
    description: "Myndband eða spilunarlisti (playlist) af Youtube.",
    // rxError: "ID gildi (11 stafir) úr slóð. Dæmi:  'https://youtube.com/watch?v=*****ID-hér*****'",
    rxErrorDescription: "Valfrjáls slóð en verður að innihalda youtube.com'",
    index: 84,
    // actionBaseUrl: "https://youtube.com/watch?v={*}",
    actionBaseUrl: "{*}",
    rxValidate: `.*youtube.com.*$`
  },
  {
    id: ActionableKindId.VIMEO,
    isClickable: true,
    impression: ActionableImpression.UNSPECIFIEDACTION,
    label: "Vimeo",
    description: "Myndband eða spilunarlisti (playlist) af Vimeo.",
    // rxError: "ID gildi. Er strengur úr slóð. Dæmi:  'https://vimeo.com/*****ID-hér*****'",
    rxErrorDescription: "Valfrjáls slóð en verður að innihalda vimeo.com",
    index: 85,
    actionBaseUrl: "{*}",
    rxValidate: `.*vimeo.com.*$`
  },
  {
    id: ActionableKindId.STATION,
    isClickable: true,
    impression: ActionableImpression.UNSPECIFIEDACTION,
    label: "Starfsstöð",
    description: "Starfsstöð í tenglasafni",
    rxErrorDescription: "ID starfsstöðvar (1-3 tölustafir).",
    index: 510,
    rxValidate: `^((?!(0))[0-9]{1,3})$`
  },
  // {
  //   id: ActionableKindId.FIXEDTOORG,
  //   isClickable: true,
  //   impression: ActionableImpression.UNSPECIFIEDACTION,
  //   label: "Borðsími stofnunar",
  //   description: "borðsími stofnunar þar sem forskeyti (t.d. 543 á LSH, 432 á HSU) er skeytt við sjálfvirkt",
  //   rxErrorDescription: "4 tölustafir. 543- er sett sjálfvirkt í forskeyti.",
  //   index: 1,
  //   rxValidate: `^((?!(0))[0-9]{4})$`
  // },
  {
    id: ActionableKindId.LINKTOACTIONABLE,
    isClickable: true,
    impression: ActionableImpression.UNSPECIFIEDACTION,
    label: "Vísun á tagg",
    description:
      "til að mótverka tvískráningar er gert kleift að spegla eða vísa í tagg sem er skráð í öðrum hlekk. Gildið hér er ID á 'ytra taggi' og er sett sjálfvirkt. ID gildi skal aðeins breytt ef þú veist hvað þú ert að gera. Ef upprunalegu taggi er breytt eða eytt er þetta synkað sjálfvirkt.",
    rxErrorDescription: null,
    index: 1,
    rxValidate: null
  }
]
