import { Select } from "antd"
import styled from "styled-components"

export const AreaFooter = styled.div`
  font-family: monospace; /* same font as Actionable label - give impression of relation label/description */
  font-size: 0.88em;
  color: slategray;
  > u {
    margin-right: 0.5em;
  }
`

export const Errors = styled.div`
  background-color: lightgoldenrodyellow;
  color: red;
  margin-top: 4px;
  padding: 4px 10px;
`

export const SelectKind = styled(Select).attrs({ size: "small" })`
  width: 260px;
`

export const MatchedEntryWrapper = styled.div`
  display: flex;
  background-color: lightgoldenrodyellow;
`
