import { PlusCircleOutlined } from "@ant-design/icons"
import { ActionableKindId, CreateActionableAndAttachMutationVariables, EntryQuery, useCreateActionableAndAttachMutation } from "@app/generated"
import { AppTitle } from "@app/lib/AppTitle"
import { PopoverIcon } from "@app/lib/PopoverIcon"
import { ViewedActionable } from "@infopage/common/lib/ViewedActionable/ViewedActionable"
import { Dropdown, Menu, Tag } from "antd"
import React, { FC, useState } from "react"
import { ActionableEditor } from "../ActionableEditor/ActionableEditor"
import { EditActionableState } from "../EntryEditor/EditActionableState"
import { Header } from "../EntryEditor/EntryEditor.styles"
import { ButtonAttach, Footer, ItemRow, Label } from "./Actionables.styles"

type Props = {
  canSelfEditEntry: boolean
  entry: EntryQuery["entry"]
  actionableKinds: EntryQuery["actionableKinds"]
}

export const Actionables: FC<Props> = ({ actionableKinds, entry: { id: entryId, actionables }, canSelfEditEntry }) => {
  const [editActionableId, setEditActionableId] = useState<EditActionableState>(null)

  const [_createActionable] = useCreateActionableAndAttachMutation({
    onCompleted: ({ createActionableAndAttach: { actionable } }) => {
      setEditActionableId(actionable.id)
    }
  })
  const createActionable = (kindId: CreateActionableAndAttachMutationVariables["kindId"]) => _createActionable({ variables: { entryId, kindId } })

  return (
    <>
      <Header>
        <AppTitle level={2} title={`${actionables.length} tögg`} />
        <PopoverIcon
          content="Tögg ákvarða hvað hlekkur gerir t.d. birta númer eða opna hlekk. Með tegundaflokkun tagga er eðli tengils nákvæmlega skilgreint til að 1) koma í veg fyrir tvískráningar 2) koma í veg fyrir ofvöxt gagnagrunns 3) gera leit
        markvissari, 4) koma í veg fyrir skörun á símanúmerum, 5) Tryggja ábyrgð eiganda (ritstjóra) á upplýsingum og réttar uppfærslur á breyttum númerum/tenglum. \n. Fyrir hverja tegund á taggi er passað að gildi sé á réttu sniði sbr. lýsing neðan við, ekki er hægt að vista nema snið sé rétt."
        />
      </Header>

      <div>
        {actionables.map(({ id, value, prefix, kind, isPublished }) => (
          <ItemRow key={id} $isActive={editActionableId === id} $isInvalidated={!isPublished}>
            <Tag>
              <Label onClick={() => setEditActionableId(id)}>{kind.label}</Label>
            </Tag>
            <ViewedActionable kindId={kind.id} value={value!} prefix={prefix} />
          </ItemRow>
        ))}
      </div>

      <ActionableEditor id={editActionableId} canUserEdit={canSelfEditEntry} closeActionableEditor={() => setEditActionableId(null)} />
      <Footer>
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu onClick={({ key }) => createActionable(key as ActionableKindId)}>
              {actionableKinds
                .filter(({ id }) => id !== ActionableKindId.Linktoactionable)
                .map(({ id, label, description }) => (
                  <Menu.Item key={id}>
                    <span title={description}>{label}</span>
                  </Menu.Item>
                ))}
            </Menu>
          }
        >
          <ButtonAttach disabled={!canSelfEditEntry} icon={<PlusCircleOutlined />}>
            nýtt tagg
          </ButtonAttach>
        </Dropdown>
      </Footer>
    </>
  )
}
