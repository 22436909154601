import React, { FC, useRef } from "react"
import { DeleteOutlined, EditTwoTone, SortAscendingOutlined } from "@ant-design/icons"
import { useMutation } from "@apollo/client"
import { SetEditEntry } from "@app/routes/userScreen/station/lib/SetEditEntry"
import { dndTypes } from "@app/routes/userScreen/station/lib/dndTypes"
import { Entries, CategoryHeader } from "@app/styles"
import {
  AttachEntryToCategoryDocument,
  StationCategorizedEntriesQuery,
  namedOperations,
  ReorderCategoryDocument,
  SortCategoryEntriesDocument,
  UpdateCategorisedEntryOrderDocument
} from "@app/generated"
import { Button } from "antd"
import { useDrag, useDrop } from "react-dnd"
import { IDragCategory } from "../lib/IDragCategory"
import { IDragEntry } from "../lib/IDragEntry"
import { EntryCategorized } from "../EntryCategorized/EntryCategorized"
import { WrapperCategory } from "./Category.styles"

const {
  Query: { StationCategorizedEntries, StationUncategorizedEntries }
} = namedOperations

const { CATEGORISEDENTRY, CATEGORY, OTHERSTATIONENTRY, UNCATEGORISEDENTRY } = dndTypes

type Props = {
  category: StationCategorizedEntriesQuery["station"]["categories"][0]
  setEditCategoryId: (id: number) => void
  setEditEntry: SetEditEntry
  deleteCategory: (id: number) => void
}

export const Category: FC<Props> = ({ deleteCategory, category: { id: categoryId, bcolor, fcolor, title, dingaloes }, setEditCategoryId, setEditEntry }) => {
  const [_reorderCategory] = useMutation(ReorderCategoryDocument, { refetchQueries: [StationCategorizedEntries] })
  const reorderCategory = (dragCategoryId: number, dropCategoryId: number) => _reorderCategory({ variables: { dragCategoryId, dropCategoryId } })

  const [_reorderEntryInCategory] = useMutation(UpdateCategorisedEntryOrderDocument, { refetchQueries: [StationCategorizedEntries] })
  const reorderEntryInCategory = (categoryId: number, dragEntryId: number, dropEntryId: number) =>
    _reorderEntryInCategory({ variables: { categoryId, dragEntryId, dropEntryId } })

  const [_sortCategoryEntries] = useMutation(SortCategoryEntriesDocument, { refetchQueries: [StationCategorizedEntries] })
  const sortCategoryEntries = () => _sortCategoryEntries({ variables: { id: categoryId } })

  const [_attachEntryToCategory] = useMutation(AttachEntryToCategoryDocument, {
    refetchQueries: [StationCategorizedEntries, StationUncategorizedEntries]
  })
  const attachEntryToCategory = (entryId: number) => _attachEntryToCategory({ variables: { entryId, categoryId } })

  const ref = useRef<HTMLDivElement>(null)

  // DnD-DROP
  // -1 Category: reorder
  // -2 CategorisedEntry: move to empty Category  (reorder categorised Entry is by DROP on Entry)
  // -3 UncategorisedEntry: categorise
  const [dropCategoryClass, dropCategoryRef] = useDrop(
    () => ({
      accept: [CATEGORY, UNCATEGORISEDENTRY, OTHERSTATIONENTRY, CATEGORISEDENTRY],
      canDrop: ({ id: itemId, type, categoryId: itemCategoryId }: any) => {
        if (type === CATEGORY && itemCategoryId === itemId) return false
        if (type === CATEGORISEDENTRY && itemCategoryId === categoryId) return false
        return true
      },
      drop: (item) => {
        if (item.type === OTHERSTATIONENTRY || item.type === UNCATEGORISEDENTRY) attachEntryToCategory((item as IDragEntry).entryId)
        if (item.type === CATEGORISEDENTRY) attachEntryToCategory((item as IDragEntry).entryId)
        if (item.type === CATEGORY) reorderCategory((item as IDragCategory).id, categoryId)
      },
      collect: (monitor) => {
        const item = monitor.getItem() as IDragCategory | null
        if (item === null) return undefined
        if (!monitor.canDrop()) return undefined

        // styling both draggable Category as well as target ("droppable")
        const dropCategoryClass = item.id === categoryId ? "dragSource" : "dragTarget"
        return `${dropCategoryClass} ${!monitor.isOver() ? "isnotOver" : "isOver"}`
      }
    }),
    [categoryId]
  )

  // DnD-DRAG Category
  const [_, dragCategoryRef] = useDrag(() => ({ item: { id: categoryId, type: CATEGORY } as IDragCategory }))

  // Merge DnDs in single ref
  dragCategoryRef(dropCategoryRef(ref))

  return (
    <WrapperCategory key={categoryId} ref={ref} className={dropCategoryClass}>
      <CategoryHeader bcolor={bcolor || undefined} fcolor={fcolor || undefined}>
        <span className="title">{title}</span>

        <span className="right">
          <Button.Group size="small">
            {dingaloes.length > 0 ? (
              <Button icon={<SortAscendingOutlined />} onClick={sortCategoryEntries} />
            ) : (
              <Button icon={<DeleteOutlined />} onClick={() => deleteCategory(categoryId)} />
            )}

            <Button icon={<EditTwoTone />} onClick={() => setEditCategoryId(categoryId)} />
          </Button.Group>
        </span>
      </CategoryHeader>

      <Entries>
        {dingaloes.map((dingalo) => (
          <EntryCategorized
            key={dingalo.id}
            entryInCategory={dingalo}
            categoryId={Number(categoryId)}
            setEditEntry={setEditEntry}
            reorderEntryInCategory={reorderEntryInCategory}
          />
        ))}
      </Entries>
    </WrapperCategory>
  )
}
