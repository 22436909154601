import { MinusCircleOutlined } from "@ant-design/icons"
import {
  namedOperations,
  useDeleteEntryInCategoryMutation,
  DeleteEntryInCategoryMutationVariables,
  UpdateEntryInCategoryMutationVariables,
  UpdateEntryMutationVariables,
  useDeleteEntryMutation,
  useEntryQuery,
  useUpdateEntryInCategoryMutation,
  useUpdateEntryMutation
} from "@app/generated"
import { AppTitle } from "@app/lib/AppTitle"
import { ModalDetails } from "@app/styles"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Button, Col, Modal, Popconfirm, Popover, Row, Select } from "antd"
import dayjs from "dayjs"
import React from "react"
import { Link } from "react-router-dom"
import { Actionables } from "../Actionables/Actionables"
import { ButtonDetachFromCategory, Footer, SelectStation, StationCategories, StationCategory, Header } from "./EntryEditor.styles"
import { Label } from "@app/lib/Label/Label"
import { StatefulInput } from "@app/lib/StatefulInput"
import { StatefulTextarea } from "@app/lib/StatefulTextarea"
import { Props } from "./Props"

const { Option } = Select

export const EntryEditor = Loadable(useEntryQuery)<Props>(
  ({
    entryId,
    entryInCategoryId = null,
    data: {
      entry,
      entry: { canSelfEditEntry, prefix, title, description, expiresAt, keyWords, createdAt, updatedAt, updatedBy, station, categorisedInStations },
      actionableKinds,
      stations
    },
    cancelEditEntry
  }) => {
    const [_updateEntry] = useUpdateEntryMutation()
    const updateEntry = (input: UpdateEntryMutationVariables["input"]) => _updateEntry({ variables: { id: entryId, input } })

    const [deleteEntry] = useDeleteEntryMutation({
      variables: { id: entryId },
      onCompleted: cancelEditEntry
    })

    const [_deleteEntryInCategory] = useDeleteEntryInCategoryMutation()
    const deleteEntryInCategory = (entryInCategoryId: DeleteEntryInCategoryMutationVariables["dingaloId"]) =>
      _deleteEntryInCategory({
        variables: { dingaloId: entryInCategoryId },
        refetchQueries: [namedOperations.Query.StationUncategorizedEntries, namedOperations.Query.StationCategorizedEntries, namedOperations.Query.Entry]
      })

    const [_updateEntryInCategory] = useUpdateEntryInCategoryMutation()
    const updateEntryInCategory = (input: UpdateEntryInCategoryMutationVariables["input"]) =>
      // eslint-disable-next-line; fn only invoked if there entryInCategoryId is set
      _updateEntryInCategory({ variables: { id: entryInCategoryId!, input } })

    return (
      <Modal
        key={entryId}
        width="800px"
        visible
        footer={false}
        onCancel={cancelEditEntry}
        closable
        title={
          <ModalDetails>
            <span>
              <u>stofnaður</u>
              <i>{dayjs(createdAt).format("L")}</i>
            </span>
            <span>
              <u>uppfærður</u>
              <i>{dayjs(updatedAt).format("L")}</i>
              &nbsp;<i>{updatedBy.name}</i>
            </span>
          </ModalDetails>
        }
      >
        <Row align="middle" gutter={[2, 16]}>
          <Col span={12}>
            <Label title="eigandi">
              <SelectStation value={station.id} onChange={(stationId) => updateEntry({ stationId: stationId as number })} disabled={!canSelfEditEntry}>
                {stations.map(({ id, title }) => (
                  <Option key={id} value={id}>
                    {title}
                  </Option>
                ))}
              </SelectStation>
            </Label>
          </Col>
        </Row>

        <Row align="middle" gutter={[2, 16]}>
          <Col span={3}>
            <Label
              title="forskeyti"
              popoverInfo="Stutt forskeyti, grálitað á undan megintitil. Notað í leitarvél. Ekki notað í stafrófsröðun."
              disabled={!canSelfEditEntry}
            >
              <StatefulInput initialValue={prefix} handleSubmit={(prefix) => updateEntry({ prefix })} />
            </Label>
          </Col>
          <Col span={12}>
            <Label title="titill" disabled={!canSelfEditEntry}>
              <StatefulInput initialValue={title} handleSubmit={(title) => updateEntry({ title })} />
            </Label>
          </Col>
          <Col span={9}>
            <Label
              title="lýsing"
              disabled={!canSelfEditEntry}
              popoverInfo="Lýsing poppar upp þegar mús er færð fyrir hlekk og getur birst fyrir neðan atriði í leitarniðurstöðum. Leitarvél notar *ekki* texta úr lýsingu (notið 'leitarorð')."
            >
              <StatefulTextarea initialValue={description} handleSubmit={(description) => updateEntry({ description })} />
            </Label>
          </Col>
        </Row>

        <Row align="middle" gutter={[2, 4]}>
          <Col span={8}>
            <Label title="gildir t.o.m." popoverInfo="hlekkur verður falinn eftir valda dagsetningu">
              <StatefulInput disabled={!canSelfEditEntry} initialValue={expiresAt} handleSubmit={(expiresAt) => updateEntry({ expiresAt })} />
            </Label>
          </Col>
          <Col span={16}>
            <Label disabled={!canSelfEditEntry} title="leitarorð (fyrir leit)">
              <StatefulInput
                initialValue={keyWords === null ? "" : keyWords.join(",")}
                handleSubmit={(keyWords) => updateEntry({ keyWords: keyWords === null ? null : keyWords.split(",") })}
              />
            </Label>
          </Col>
        </Row>

        {categorisedInStations !== null && (
          <Row align="middle" gutter={[2, 4]}>
            <Col span={24}>
              <Header>
                <AppTitle level={2} title={`${categorisedInStations.length} birtingar`} />
              </Header>

              <StationCategories>
                {categorisedInStations.map(({ id, title, altTitle, categoryTitle, entryInCategoryId: itemEntryInCategoryId }) => (
                  <StationCategory $isTarget={entryInCategoryId === itemEntryInCategoryId} key={itemEntryInCategoryId}>
                    <Link key={id} to={`/editStation/${id}`}>
                      {title}
                    </Link>
                    <span>{categoryTitle}</span>
                    {entryInCategoryId === itemEntryInCategoryId && (
                      <Popover title="Taka úr flokk (birtingu).">
                        <ButtonDetachFromCategory size="small" icon={<MinusCircleOutlined />} onClick={() => deleteEntryInCategory(itemEntryInCategoryId)} />
                      </Popover>
                    )}
                    {entryInCategoryId === itemEntryInCategoryId && (
                      <div>
                        <Label
                          title="sérstakur titill"
                          popoverInfo="Hlekkur getur haft titil annan en upprunalegan frá hlekk, þar sem hann er í flokki í starfsstöð. Sá titill verður notaður í leit."
                        >
                          <StatefulInput
                            disabled={!canSelfEditEntry}
                            initialValue={altTitle}
                            handleSubmit={(altTitle) => updateEntryInCategory({ altTitle })}
                          />
                        </Label>
                      </div>
                    )}
                  </StationCategory>
                ))}
              </StationCategories>
            </Col>
          </Row>
        )}

        <Row align="middle" gutter={[2, 16]}>
          <Col span={24}>
            <Actionables entry={entry} canSelfEditEntry={canSelfEditEntry} actionableKinds={actionableKinds} />
          </Col>
        </Row>

        {/* don't use Modals footer! */}
        <Footer>
          <Button onClick={cancelEditEntry} children="Loka" />

          <Popconfirm
            title={`Viltu eyða hlekk?${
              categorisedInStations !== null && categorisedInStations.length > 0
                ? `\nATH hlekkur er í ${categorisedInStations.length} birtingum og verður eytt!`
                : ""
            } `}
            onConfirm={() => deleteEntry()}
          >
            <Button danger disabled={!canSelfEditEntry}>
              Eyða
            </Button>
          </Popconfirm>
          <Button type="primary" onClick={(e) => cancelEditEntry()}>
            Vista
          </Button>
        </Footer>
      </Modal>
    )
  },
  ({ entryId }) => ({ id: entryId })
)
