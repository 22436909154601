import styled from "styled-components"

/**
 * Impression: an area of "unit", having (sub)title and content (or empty)
 */

export const EntitiesArea = styled.div`
  border: 1px solid lightgray;
  margin-top: 1.6em;
  /* to prevent ugly DnD 'fallout' effect */
  min-height: 160px;

  > .subTitle {
    background: WHITESMOKE;
    margin-bottom: 5px;
    display: flex;
    padding: 0.2em 0.3em;
    /* title itself */
    users,
    stations,
    > span {
      font-size: 1.1em;
      font-weight: bold;
      margin-right: 1em;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  > .content {
    min-height: 2em;
    padding: 0.5em;
  }
`
