import { StationUncategorizedEntriesQuery } from "@app/generated"
import React, { FC } from "react"
import { useDrag } from "react-dnd"
import { dndTypes } from "@app/routes/userScreen/station/lib/dndTypes"
import { EditEntryWrapper } from "@app/lib/EditEntryWrapper/EditEntryWrapper"
import { SetEditEntry } from "@app/routes/userScreen/station/lib/SetEditEntry"

export const UnCategorisedEntry: FC<{
  entry: StationUncategorizedEntriesQuery["station"]["nonCategorisedEntries"][0]
  setEditEntry: SetEditEntry
}> = ({ entry, setEditEntry }) => {
  const [_, dragEntryRef] = useDrag(() => ({
    item: { type: dndTypes.UNCATEGORISEDENTRY, entryId: entry.id }
  }))

  return <EditEntryWrapper $ref={dragEntryRef} entry={entry} handleClick={setEditEntry} />
}
