// COMEBACK do not use numbers, only causes troubles
export enum ActionableKindId {
  LSHFIXEDPHONE = 4,
  HSUFIXEDPHONE = 5,
  LSHPAGER = 2,
  LSHINFO = 26,
  GAEDAHANDBOK = 22,
  BRUNNUR = 23,
  //
  MOBILE = 6,
  PHONEOTHER = 8,
  GENERALURL = 20,
  GOOGLEDRIVE = 30,
  YOUTUBE = 31,
  VIMEO = 32,
  // To other Infopage Station
  STATION = 52,
  // When user adds an actionable kind/value which already exists, will be provided with option to set as link instead. Purpose is to preserve single origin of Actionables
  LINKTOACTIONABLE = 100
}
