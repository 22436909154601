import styled from "styled-components"

export const Entries = styled.div`
  column-gap: 20px;
  column-width: 280px;

  > div {
    margin: 2px 0.2em;
    break-inside: avoid;
  }
`
