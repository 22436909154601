import React from "react"
import { Redirect, Route } from "react-router-dom"
import { useAuth } from "@app/lib/auth"

interface Props {
  component: React.FunctionComponent
  path: string
}

const PrivateRoute = ({ component, ...options }: Props) => {
  const { isAuthorized } = useAuth()

  if (!isAuthorized) return <Redirect to="/login" />

  return <Route {...options} component={component} />
}

export default PrivateRoute
