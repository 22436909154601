import React from "react"
import { useSearchOtherStationEntriesQuery } from "@app/generated"
import { Loadable } from "@app/lib/Loadable/Loadable"
import styled from "styled-components"
import { SearchOtherStationEntry } from "../SearchOtherStationEntry/SearchOtherStationEntry"

const Wrapper = styled.div`
  border: 2px dotted blue;
  padding: 6px 12px;
`

export const SearchOtherStationEntries = Loadable(useSearchOtherStationEntriesQuery)<{ value: string; excludeStationId: number }>(
  ({ data: { entries } }) => (
    <Wrapper>
      {entries.map((entry) => (
        <SearchOtherStationEntry key={entry.id} entry={entry} />
      ))}
    </Wrapper>
  ),
  ({ value, excludeStationId }) => ({ value, excludeStationId })
)
