import { InfoCircleFilled, LoadingOutlined } from "@ant-design/icons"
import { ApolloQueryResult, QueryHookOptions, QueryResult } from "@apollo/client"
import { message } from "antd"
import React, { FC } from "react"

type Options = {
  loadingBig?: boolean
  loadingWhite?: boolean
  showLoading?: boolean
}

export const Loadable =
  <TData, TVariables>(
    useHook: (baseOptions: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
    options?: Options
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) =>
  <TProps extends any>(
    WrappedComponent: FC<TProps & { data: TData; refetch: (variables?: TVariables) => Promise<ApolloQueryResult<TData>> }>,
    mapPropsToVars?: (props: TProps) => TVariables,
    mapPropsToOptions?: (props: TProps) => Omit<QueryHookOptions<TData, TVariables>, "variables">
  ) =>
  (props: TProps) => {
    // react-hooks/rules-of-hooks cannot detect that this anonymous function is a React component
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, error, loading, refetch } = useHook({
      variables: mapPropsToVars ? mapPropsToVars(props) : undefined,
      ...(mapPropsToOptions && mapPropsToOptions(props))
    })

    // Always return null component if skip
    if (mapPropsToOptions !== undefined) {
      const { skip } = mapPropsToOptions(props)
      if (skip === true) return null
    }

    // no need to console.log, Loadable.tsx does it
    if (error) {
      message.error(error.message, 5)
    }

    if (loading) {
      if (options?.showLoading === false) {
        return null
      }

      return <LoadingOutlined style={{ fontSize: "1.5em" }} />
    }

    if (data === undefined) return <InfoCircleFilled style={{ color: "RED" }} />

    return <WrappedComponent {...props} data={data} refetch={refetch} />
  }
