import React, { FC } from "react"
import { CategoryEditor } from "../CategoryEditor/CategoryEditor"
import { EntryEditor } from "../EntryEditor/EntryEditor"
import { StationEditor } from "../StationEditor/StationEditor"

type Props = {
  editStation?: number
  editEntryId?: number
  editEntryInCategoryId?: number
  editCategoryId?: number
  cancelEditEntry: () => void
  cancelEditStation: () => void
  cancelEditCategory: () => void
}

export const ConductEditor: FC<Props> = ({
  editStation,
  cancelEditEntry,
  cancelEditStation,
  cancelEditCategory,
  editEntryId: entryId,
  editEntryInCategoryId,
  editCategoryId
}) => (
  <div>
    {editStation && <StationEditor id={editStation} closeEditStation={cancelEditStation} />}

    {editCategoryId && <CategoryEditor id={editCategoryId} closeCategoryEditor={cancelEditCategory} />}

    {entryId && <EntryEditor entryId={entryId} entryInCategoryId={editEntryInCategoryId} cancelEditEntry={cancelEditEntry} />}
  </div>
)
