import { CheckCircleOutlined } from "@ant-design/icons"
import { Input } from "antd"
import React, { FC, useEffect, useState } from "react"

export const StatefulInput: FC<{
  initialValue: string | null
  autoFocus?: boolean
  placeholder?: string
  disabled?: boolean
  successFul?: boolean
  handleSubmit: (value: string | null) => void
  isPasswordType?: boolean
  // COMEBACK what the fucking hell is going on here, disabled is always undefined whetever parent components sets!!
}> = ({ handleSubmit, isPasswordType, initialValue, autoFocus = false, placeholder, disabled, successFul }) => {
  const [value, setValue] = useState<string | null>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <Input
      value={value || ""}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={disabled}
      onChange={({ target }) => setValue(target.value)}
      onBlur={() => handleSubmit(value === "" ? null : value)}
      suffix={successFul ? <CheckCircleOutlined style={{ color: "green" }} /> : undefined}
      spellCheck={false}
      type={isPasswordType === true ? "password" : "text"}
    />
  )
}
