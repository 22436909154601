import { Select } from "antd"
import styled from "styled-components"

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  > .moveRight {
    margin-left: auto;
    display: flex;
    justify-content: baseline;
  }
`

export const SelectOtherStation = styled(Select)`
  width: 16em;
`

export const StationPrefix = styled.span`
  font-size: 1.1em;
  margin-right: 6px;
  color: gray;
`

export const StationTitle = styled.span`
  font-weight: bold;
  font-size: 1.4em;
  max-width: 360px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 12px;
`
