// The mini-button rendered, what impression gives user?

export enum ActionableImpression {
  MOBILEORPAGER,
  FIXEDPHONE,
  PHONEGENERAL,
  LSHKIOSK,
  LSHINFO,
  UNSPECIFIEDACTION
}
