import { ListEntryFieldsFragment } from "@app/generated"
import React, { FC } from "react"
import { EntryItem, Prefix, Wrapper } from "./MatchedEntry.styles"

export type Props = {
  entry: ListEntryFieldsFragment
}

export const MatchedEntry: FC<Props> = ({ entry: { title, prefix, dingaloTitle } }) => (
  <Wrapper>
    {prefix !== null && <Prefix>{prefix}</Prefix>}

    <EntryItem>{dingaloTitle || title}</EntryItem>
  </Wrapper>
)
