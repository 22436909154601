import { useOtherStationCategorizedEntriesQuery } from "@app/generated"
import { AppTitle } from "@app/lib/AppTitle"
import { SetEditEntry } from "@app/routes/userScreen/station/lib/SetEditEntry"
import { Categories as _Categories, Entries, CategoryHeader, Wrapper } from "@app/styles"
import { Loadable } from "@app/lib/Loadable/Loadable"
import React from "react"
import styled from "styled-components"
import { WrapperCategory } from "../Category/Category.styles"
import { OtherStationEntry } from "../OtherStationEntry/OtherStationEntry"

const Categories = styled(_Categories)`
  max-height: 300px;
  overflow: auto;
  border-left: 2px solid GREEN;
`

export const OtherStation = Loadable(useOtherStationCategorizedEntriesQuery)<{ id: number; setEditEntry: SetEditEntry }>(
  ({
    setEditEntry,
    data: {
      station: { id: stationId, prefix, title, categories }
    }
  }) => (
    <Wrapper>
      <AppTitle title={title} titleTo={`/station/${stationId}`} prefix={prefix || "---"} level={2} />

      <Categories className="content">
        {categories.map(({ id, title, dingaloes }) => (
          <WrapperCategory key={id}>
            <CategoryHeader>
              <span className="title">{title}</span>
            </CategoryHeader>

            <Entries>
              {dingaloes.map((entry) => (
                <OtherStationEntry key={entry.id} entry={entry.entry} setEditEntry={setEditEntry} />
              ))}
            </Entries>
          </WrapperCategory>
        ))}
      </Categories>
    </Wrapper>
  ),
  ({ id }) => ({ id })
)
