import { LogoutOutlined } from "@ant-design/icons"
import { GlobalRoleId, useUserScreenQuery } from "@app/generated/"
import { useAuth } from "@app/lib/auth"
import { ListAllStations } from "@app/routes/userScreen/Stations/Stations"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Button, Col, Layout, Popover, Row, Tag } from "antd"
import React from "react"
import { Link, Route, Switch, useLocation } from "react-router-dom"
import { Station } from "../station/Station/Station"
import Users from "../Users/Users"
import { Content, Header, MenuItem, NavigationMenu, UserArea } from "./FrontPage.styles"

export const FrontPage = Loadable(useUserScreenQuery)<{}>(
  ({
    data: {
      globalRoles,
      self: { name, globalRole, appBuildInfo }
    }
  }) => {
    const { pathname } = useLocation()
    const { deauthorize } = useAuth()

    if (globalRole.id === GlobalRoleId.Supermoderator) console.dir(appBuildInfo)

    return (
      <Layout hasSider={false}>
        <Header>
          <Row justify="space-between">
            <Col flex="1 1 600px">
              <NavigationMenu mode="horizontal">
                <MenuItem key="stations" $isActive={pathname === "/listStations"}>
                  <Link to="/listStations">Starfsstöðvar</Link>
                </MenuItem>
                <MenuItem key="users" $isActive={pathname === "/listUsers"}>
                  <Link to="/listUsers">Ritstjórar</Link>
                </MenuItem>
                <MenuItem key="guide">
                  <a target="_new" href="https://docs.google.com/document/d/126x3aNXnXDT6wZJzzLSNBbFW8m6FtdMUCZULmdfND7w/edit#">
                    Leiðbeiningar
                  </a>
                </MenuItem>
                <MenuItem key="emoji">
                  <a href="https://emojipedia.org" target="_new">
                    Emojis
                  </a>
                </MenuItem>
              </NavigationMenu>
            </Col>

            <UserArea>
              <Popover
                autoAdjustOverflow
                placement="bottomLeft"
                content={globalRoles.map(({ id, label, description }) => (
                  <div key={id} style={{ maxWidth: "380px", fontSize: "0.92em", margin: "4px 0" }}>
                    <u>{label}</u>: {description}
                  </div>
                ))}
              >
                <Tag color="gold" style={{ marginLeft: "8px" }}>
                  {globalRole.label}
                </Tag>
              </Popover>

              <Button onClick={deauthorize}>
                <LogoutOutlined />
              </Button>
            </UserArea>
          </Row>
        </Header>

        <Content>
          <Switch>
            <Route path="/editStation/:stationId/:eetuplet?" component={Station} />
            <Route path="/listUsers/:userId?" component={Users} />
            <Route path="/listStations" component={ListAllStations} />
            <Route path="/" render={() => <ListAllStations />} />
          </Switch>
        </Content>
      </Layout>
    )
  }
)
