import React, { createContext, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { client } from "../apollo"

interface AuthContextType {
  isAuthorized: boolean
  authorize: (token: string) => void
  deauthorize: () => void
}

const tokensBox = { current: localStorage.getItem("token") }

export const getToken = () => tokensBox.current

const AuthContext = createContext<AuthContextType>({
  isAuthorized: false,
  authorize: () => {},
  deauthorize: () => {}
})

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const _isAuthorized = getToken() !== null
  const [isAuthorized, setAuthorized] = useState(_isAuthorized)

  const authorize = async (token: string) => {
    localStorage.setItem("token", token)
    tokensBox.current = token
    setAuthorized(true)
    await client.resetStore()
    history.push("/")
  }

  const deauthorize = async () => {
    localStorage.removeItem("token")
    tokensBox.current = null
    setAuthorized(false)
    await client.resetStore()
    history.push("/login")
  }

  return <AuthContext.Provider value={{ isAuthorized, authorize, deauthorize }}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
