import { EditTwoTone } from "@ant-design/icons"
import { useStationQuery } from "@app/generated"
import { PopoverIcon } from "@app/lib/PopoverIcon"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Alert, Button, Input, Select } from "antd"
import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import useDebounce from "use-debounce/lib/useDebounce"
import { ConductEditor } from "../ConductEditor/ConductEditor"
import { OtherStation } from "../OtherStation/OtherStation"
import { SearchOtherStationEntries } from "./SearchOtherStationEntries"
import { Categories } from "../Categories/Categories"
import { UnCategorisedEntries } from "../UnCategorisedEntries/UnCategorisedEntries"
import { Header, SelectOtherStation, StationPrefix, StationTitle } from "./Station.styles"
const { Option } = Select

export const Station = Loadable(useStationQuery)<RouteComponentProps<{ stationId: Extract<string, number>; eetuplet?: string }>>(
  ({
    history,
    match: {
      params: { eetuplet = "," }
    },
    data: {
      station: { id: stationId, prefix, title, canSelfEdit },
      allStations
    }
  }) => {
    const [editStation, setEditStation] = useState<number | undefined>()
    const [editCategoryId, setEditCategoryId] = useState<number | undefined>()
    const [otherStationId, setOtherStationId] = useState<number | undefined>()
    const [searchEntryValue, setSearchEntryValue] = useState("")
    const [debouncedSearchEntryValue] = useDebounce(searchEntryValue, 300)

    const [editEntryId, editEntryInCategoryId] = eetuplet.split(",").map((id) => (id ? Number(id) : undefined))
    const setEditEntry = (entryId?: number, dingaloId?: number) =>
      history.push(`/editStation/${stationId}/${[entryId, dingaloId].map((id) => (id === undefined ? "" : id)).join(",")}`)

    return (
      <>
        {!canSelfEdit && <Alert message="Þú hefur ekki ritstjóraaðgang fyrir starfsstöð." showIcon type="error" />}

        <Header>
          <StationPrefix>{prefix ?? "---"}</StationPrefix>
          <StationTitle>{title}</StationTitle>

          <div>
            {/* danger signals that self has 'supereditor' rights */}
            <Button icon={<EditTwoTone />} onClick={() => setEditStation(stationId)} />
          </div>

          <div className="moveRight">
            <div>
              <Input
                placeholder="leit að (ytra) hlekk"
                value={searchEntryValue}
                allowClear
                width="120px"
                suffix={<PopoverIcon content="leitar í öðrum starfsstöðvum. Heiti, leitarorð, lýsing, ID" />}
                onChange={({ target }) => setSearchEntryValue(target.value)}
              />
            </div>

            <div>
              <SelectOtherStation showSearch allowClear placeholder="önnur starfsstöð" onChange={(value) => setOtherStationId(Number(value))}>
                {allStations
                  .filter(({ id }) => stationId !== id)
                  .map(({ id, title }) => (
                    <Option value={id} key={id} title={title}>
                      {title}
                    </Option>
                  ))}
              </SelectOtherStation>
              <PopoverIcon content="Opna aðra starfsstöð til að nota hlekki úr og birta í efnisflokkum hér" />
            </div>
          </div>
        </Header>

        {debouncedSearchEntryValue.length > 2 && debouncedSearchEntryValue === searchEntryValue && (
          <SearchOtherStationEntries value={debouncedSearchEntryValue!} excludeStationId={stationId} />
        )}

        {otherStationId === undefined ? (
          <UnCategorisedEntries setEditEntry={setEditEntry} id={stationId} canSelfEdit={canSelfEdit} />
        ) : (
          <OtherStation id={otherStationId} setEditEntry={setEditEntry} />
        )}

        <Categories id={stationId} setEditEntry={setEditEntry} setEditCategoryId={setEditCategoryId} canSelfEdit={canSelfEdit} />

        <ConductEditor
          editStation={editStation}
          editEntryId={editEntryId}
          editEntryInCategoryId={editEntryInCategoryId}
          editCategoryId={editCategoryId}
          cancelEditEntry={() => setEditEntry(undefined)}
          cancelEditStation={() => setEditStation(undefined)}
          cancelEditCategory={() => setEditCategoryId(undefined)}
        />
      </>
    )
  },
  ({
    match: {
      params: { stationId }
    }
  }) => ({ id: Number(stationId) })
)
