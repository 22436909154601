import React, { FC } from "react"
import styled, { css } from "styled-components"
import { AppLink } from "./AppLink"

const commonCss = css`
  margin-top: 1.4em;
  font-weight: bold;
  /* antd peculiarity */
  margin-left: 0;
`

const AppH1 = styled("h1")`
  ${commonCss}
  font-size: 1.6em;
`

const AppH2 = styled("h2")`
  ${commonCss}
  font-size: 1.3em;
`

const Prefix = styled.span`
  color: GRAY;
  font-size: 0.9em;
  margin-right: 0.5em;
`

/**
 * For consistent Title layout in Application.
 * */
export const AppTitle: FC<{
  title: string
  titleTo?: string
  prefix?: string
  level?: number
}> = ({ prefix, title, level = 1, titleTo }) => {
  if (level > 2) throw new Error("Only supporting Title levels 1-2")

  const Content = (
    <>
      {prefix !== undefined && <Prefix>{prefix}</Prefix>}
      {titleTo !== undefined ? <AppLink route={titleTo} label={title} /> : <span>{title}</span>}
    </>
  )

  // create conditional h1/h2
  return level === 1 ? <AppH1>{Content}</AppH1> : level === 2 ? <AppH2>{Content}</AppH2> : null
}
