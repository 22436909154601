import { actionableKindItems } from "../actionableKindItems"
import { ActionableKindId } from "../ActionableKindId.enum"
import { ActionableForTrigger } from "./ActionableForTrigger"
import { openAndFocus } from "./openAndFocus"
import React from "react"

export const triggerActionable = ({ e, kindId, value }: { e?: React.MouseEvent } & Pick<ActionableForTrigger, "kindId" | "value">) => {
  e?.stopPropagation()
  const { id, actionBaseUrl, isClickable } = actionableKindItems.find(({ id }) => id === kindId)!

  if (isClickable === false) return

  if (actionBaseUrl !== undefined) {
    const openUrl = `${actionBaseUrl.replace(/\{\*\}/, value)}`
    return openAndFocus(openUrl)
  }

  // no actionBaseUrl => action requires custom logic based on ActionableKind
  switch (id) {
    case ActionableKindId.STATION:
      window.location.href = `/stationId=${value}`
      break
  }
}
