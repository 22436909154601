import React, { FunctionComponent, useState } from "react"
import { Result, Button } from "antd"
import { useAuth } from "@app/lib/auth"
import { useLoginMutation } from "@app/generated"
import { Area, InputFields, LoginForm, Submit, SubTitle, Title } from "./Login.styles"
import { Label } from "@app/lib/Label/Label"
import { StatefulInput } from "@app/lib/StatefulInput"

export const Login: FunctionComponent<{}> = () => {
  const { authorize } = useAuth()
  const [result, setResult] = useState<string | undefined>(undefined)
  const [ssn, setSsn] = useState("")
  const [password, setPassword] = useState("")

  const [_login] = useLoginMutation({
    onCompleted: ({ logIn: { accessToken } }) => {
      if (accessToken) authorize(accessToken)
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors[0] && graphQLErrors[0].message){
        setResult(graphQLErrors[0].message)
      } else {
        console.error({ graphQLErrors }, "Error setting result graphQLErrors[0].message is not defined")
        setResult("Unknown error")
      }
    }
  })

  return (
    <LoginForm>
      <Title>Upplýsingasíða</Title>
      <SubTitle>(Tenglasafn og símaskrá)</SubTitle>
      <Area>
        <InputFields>
          <Label title="Kennitala">
            <StatefulInput autoFocus initialValue={ssn} handleSubmit={(newSsn) => setSsn(newSsn || "")} />
          </Label>
          <Label title="Lykilorð">
            <StatefulInput isPasswordType initialValue={password} handleSubmit={(newPassword) => setPassword(newPassword || "")} />
          </Label>
        </InputFields>

        <Submit>
          <Button type="primary" htmlType="submit" onClick={() => _login({ variables: { ssn, password } })}>
            Innskrá
          </Button>
        </Submit>
      </Area>
      {result && <Result status="warning" title={result} />}
    </LoginForm>
  )
}
