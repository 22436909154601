import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Category = {
  readonly id: Scalars['Int'];
  readonly createdAt: Scalars['DateTime'];
  readonly index: Scalars['Int'];
  readonly fcolor: Maybe<Scalars['String']>;
  readonly bcolor: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly dingaloes: ReadonlyArray<EntryInCategory>;
};


export type CategoryTitleArgs = {
  raw: Maybe<Scalars['Boolean']>;
};


export type Session = {
  readonly id: Scalars['ID'];
  readonly lastUsed: Scalars['DateTime'];
};

export type User = {
  readonly id: Scalars['Int'];
  readonly isDisabled: Scalars['Boolean'];
  readonly ssn: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly latestLoginAt: Maybe<Scalars['DateTime']>;
  readonly email: Maybe<Scalars['String']>;
  readonly isGlobalModerator: Scalars['Boolean'];
  readonly isGlobalAdministrator: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly globalRole: GlobalRole;
  readonly editorForStations: ReadonlyArray<Station>;
  readonly appBuildInfo: ReadonlyArray<Scalars['String']>;
};


export type UserNameArgs = {
  raw: Maybe<Scalars['Boolean']>;
};

export type Station = {
  readonly id: Scalars['Int'];
  readonly createdAt: Scalars['DateTime'];
  readonly prefix: Maybe<Scalars['String']>;
  readonly description: Maybe<Scalars['String']>;
  readonly lshDepartments: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly orgsWantData: ReadonlyArray<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedBy: User;
  readonly userModeratorId: Scalars['Float'];
  readonly title: Scalars['String'];
  readonly showEntryDescription: StationShowEntryDescription;
  readonly isPublished: Scalars['Boolean'];
  readonly userModerator: User;
  readonly editors: ReadonlyArray<User>;
  readonly categories: ReadonlyArray<Category>;
  readonly entries: ReadonlyArray<Entry>;
  /** Get all Entries owned by Station and not categorised. */
  readonly nonCategorisedEntries: ReadonlyArray<Entry>;
  readonly canSelfEdit: Scalars['Boolean'];
};


export type StationTitleArgs = {
  raw: Maybe<Scalars['Boolean']>;
};

export type Entry = {
  readonly id: Scalars['Int'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly expiresAt: Maybe<Scalars['DateTime']>;
  readonly prefix: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly keyWords: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly updatedBy: User;
  readonly station: Station;
  readonly dingaloTitle: Scalars['String'];
  readonly hasUnpublishedActionables: Scalars['Boolean'];
  readonly categorisedInStations: Maybe<ReadonlyArray<StationEntryInCategory>>;
  readonly isClickable: Scalars['Boolean'];
  readonly actionables: ReadonlyArray<Actionable>;
  readonly actionablesCount: Scalars['Int'];
  readonly canSelfEdit: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type EntryInCategory = {
  readonly id: Scalars['Int'];
  readonly index: Scalars['Int'];
  readonly altTitle: Maybe<Scalars['String']>;
  readonly entryId: Scalars['Int'];
  readonly categoryId: Scalars['Int'];
  readonly isBorrowed: Scalars['Boolean'];
  readonly entry: Entry;
  readonly category: Category;
};

export type AuthPayload = {
  readonly accessToken: Scalars['String'];
};

export type GlobalRole = {
  readonly id: GlobalRoleId;
  readonly label: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
};

export enum GlobalRoleId {
  Generic = 'GENERIC',
  Moderator = 'MODERATOR',
  Supermoderator = 'SUPERMODERATOR'
}

export type StationShowEntryDescription = KindDescriptor & {
  readonly id: Scalars['Int'];
  readonly label: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly showEntryDescription: StationShowEntryDescription;
};

export type Actionable = {
  readonly id: Scalars['Int'];
  readonly value: Maybe<Scalars['String']>;
  readonly prefix: Maybe<Scalars['String']>;
  readonly isPublished: Scalars['Boolean'];
  readonly kind: ActionableKindModel;
};

export type ActionableKindModel = {
  readonly id: ActionableKindId;
  readonly label: Scalars['String'];
  readonly description: Scalars['String'];
  readonly impression: Scalars['Int'];
  readonly disabled: Scalars['Boolean'];
};

export enum ActionableKindId {
  Lshfixedphone = 'LSHFIXEDPHONE',
  Hsufixedphone = 'HSUFIXEDPHONE',
  Lshpager = 'LSHPAGER',
  Lshinfo = 'LSHINFO',
  Gaedahandbok = 'GAEDAHANDBOK',
  Brunnur = 'BRUNNUR',
  Mobile = 'MOBILE',
  Phoneother = 'PHONEOTHER',
  Generalurl = 'GENERALURL',
  Googledrive = 'GOOGLEDRIVE',
  Youtube = 'YOUTUBE',
  Vimeo = 'VIMEO',
  Station = 'STATION',
  Linktoactionable = 'LINKTOACTIONABLE'
}

export type ActionableUpdateOutput = {
  readonly actionable: Actionable;
  readonly validationError: Maybe<Scalars['String']>;
  readonly matchedEntry: Maybe<Entry>;
};

export type CreateActionableAndAttachOutput = {
  readonly entry: Entry;
  readonly actionable: Actionable;
};

export type StationEntryInCategory = {
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly entryInCategoryId: Scalars['Int'];
  readonly altTitle: Maybe<Scalars['String']>;
  readonly categoryTitle: Scalars['String'];
};

export type EntryWithCategorisedEntry = {
  readonly entry: Entry;
  readonly entryInCategory: Maybe<EntryInCategory>;
};

export type KindDescriptor = {
  readonly id: Scalars['Int'];
  readonly label: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
};

export type UserUpdateInput = {
  readonly isDisabled?: Maybe<Scalars['Boolean']>;
  readonly ssn?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly globalRoleId?: Maybe<GlobalRoleId>;
};

export type StationUpdateInput = {
  readonly title?: Maybe<Scalars['String']>;
  readonly isPublished?: Maybe<Scalars['Boolean']>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly lshDepartments?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly editorIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly showEntryDescriptionId?: Maybe<Scalars['Int']>;
  readonly userModeratorId?: Maybe<Scalars['Int']>;
};

export type ActionableUpdateInput = {
  readonly kindId?: Maybe<ActionableKindId>;
  readonly value?: Maybe<Scalars['String']>;
  readonly prefix?: Maybe<Scalars['String']>;
};

export type EntryUpdateInput = {
  readonly expiresAt?: Maybe<Scalars['DateTime']>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly keyWords?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly stationId?: Maybe<Scalars['Int']>;
};

export type EntryInCategoryUpdateInput = {
  readonly altTitle?: Maybe<Scalars['String']>;
};

export type CategoryUpdateInput = {
  readonly index?: Maybe<Scalars['Float']>;
  readonly bcolor?: Maybe<Scalars['String']>;
  readonly fcolor?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type EntryWithCategorisedEntryUpdate = {
  readonly entry: EntryUpdateInput;
  readonly entryInCategory?: Maybe<EntryInCategoryUpdateInput>;
};

export type Query = {
  readonly user: User;
  readonly users: ReadonlyArray<User>;
  readonly self: User;
  readonly globalRoles: ReadonlyArray<GlobalRole>;
  readonly showEntryDescriptions: ReadonlyArray<StationShowEntryDescription>;
  readonly station: Station;
  readonly stations: ReadonlyArray<Station>;
  readonly actionable: Actionable;
  readonly actionableKinds: ReadonlyArray<ActionableKindModel>;
  readonly entry: Entry;
  readonly searchOtherStationEntries: ReadonlyArray<Entry>;
  readonly entryInCategory: EntryInCategory;
  readonly category: Category;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryStationArgs = {
  id: Scalars['Int'];
};


export type QueryActionableArgs = {
  wantsEdit: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};


export type QueryEntryArgs = {
  id: Scalars['Int'];
};


export type QuerySearchOtherStationEntriesArgs = {
  excludeStationId: Scalars['Int'];
  value: Maybe<Scalars['String']>;
};


export type QueryEntryInCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryCategoryArgs = {
  id: Scalars['Int'];
};

export type Mutation = {
  readonly logOut: Scalars['Boolean'];
  readonly createUser: User;
  readonly updateUser: User;
  readonly deleteUser: Scalars['Boolean'];
  readonly logIn: AuthPayload;
  readonly createStation: Station;
  readonly updateStation: Station;
  readonly deleteStation: Scalars['Boolean'];
  readonly createActionableAndAttach: CreateActionableAndAttachOutput;
  readonly deleteActionable: Entry;
  readonly createReferenceForFirstDuplicate: Actionable;
  readonly updateActionable: ActionableUpdateOutput;
  readonly createEntry: Entry;
  readonly updateEntry: Entry;
  readonly deleteEntry: Scalars['Int'];
  readonly updateEntryInCategory: EntryInCategory;
  readonly deleteEntryInCategory: Scalars['Boolean'];
  readonly attachEntryToCategory: Scalars['Boolean'];
  /** Move dragged Entry to dropped Entry within Category. */
  readonly updateCategorisedEntryOrder: ReadonlyArray<EntryInCategory>;
  readonly createCategory: Category;
  readonly updateCategory: Category;
  readonly deleteCategory: Scalars['Boolean'];
  readonly sortCategoryEntries: Category;
  readonly reorderCategory: ReadonlyArray<Category>;
  readonly updateEntryWithCategorisedEntry: EntryWithCategorisedEntry;
};


export type MutationCreateUserArgs = {
  ssn: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationLogInArgs = {
  password: Scalars['String'];
  ssn: Scalars['String'];
};


export type MutationUpdateStationArgs = {
  input: StationUpdateInput;
  id: Scalars['Int'];
};


export type MutationDeleteStationArgs = {
  id: Scalars['Int'];
};


export type MutationCreateActionableAndAttachArgs = {
  kindId: ActionableKindId;
  entryId: Scalars['Int'];
};


export type MutationDeleteActionableArgs = {
  id: Scalars['Int'];
};


export type MutationCreateReferenceForFirstDuplicateArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateActionableArgs = {
  input: ActionableUpdateInput;
  id: Scalars['Int'];
};


export type MutationCreateEntryArgs = {
  stationId: Scalars['Int'];
};


export type MutationUpdateEntryArgs = {
  input: EntryUpdateInput;
  id: Scalars['Int'];
};


export type MutationDeleteEntryArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateEntryInCategoryArgs = {
  input: EntryInCategoryUpdateInput;
  id: Scalars['Int'];
};


export type MutationDeleteEntryInCategoryArgs = {
  dingaloId: Scalars['Int'];
};


export type MutationAttachEntryToCategoryArgs = {
  categoryId: Scalars['Int'];
  entryId: Scalars['Int'];
};


export type MutationUpdateCategorisedEntryOrderArgs = {
  dropEntryId: Scalars['Int'];
  dragEntryId: Scalars['Int'];
  categoryId: Scalars['Int'];
};


export type MutationCreateCategoryArgs = {
  stationId: Scalars['Int'];
};


export type MutationUpdateCategoryArgs = {
  input: CategoryUpdateInput;
  id: Scalars['Int'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationSortCategoryEntriesArgs = {
  id: Scalars['Int'];
};


export type MutationReorderCategoryArgs = {
  dropCategoryId: Scalars['Int'];
  dragCategoryId: Scalars['Int'];
};


export type MutationUpdateEntryWithCategorisedEntryArgs = {
  input: EntryWithCategorisedEntryUpdate;
  entryInCategoryId: Maybe<Scalars['Int']>;
  entryId: Scalars['Int'];
};

export type LoginMutationVariables = Exact<{
  ssn: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly logIn: (
    { readonly __typename: 'AuthPayload' }
    & Pick<AuthPayload, 'accessToken'>
  ) }
);

export type SelfFieldsFragment = (
  { readonly __typename: 'User' }
  & Pick<User, 'id' | 'name'>
);

export type UserScreenQueryVariables = Exact<{ [key: string]: never; }>;


export type UserScreenQuery = (
  { readonly __typename: 'Query' }
  & { readonly self: (
    { readonly __typename: 'User' }
    & Pick<User, 'appBuildInfo'>
    & { readonly globalRole: (
      { readonly __typename: 'GlobalRole' }
      & Pick<GlobalRole, 'id' | 'label'>
    ) }
    & SelfFieldsFragment
  ), readonly globalRoles: ReadonlyArray<(
    { readonly __typename: 'GlobalRole' }
    & Pick<GlobalRole, 'id' | 'label' | 'description'>
  )> }
);

export type StationsQueryVariables = Exact<{ [key: string]: never; }>;


export type StationsQuery = (
  { readonly __typename: 'Query' }
  & { readonly stations: ReadonlyArray<(
    { readonly __typename: 'Station' }
    & Pick<Station, 'id' | 'updatedAt' | 'prefix' | 'title' | 'isPublished' | 'canSelfEdit'>
    & { readonly userModerator: (
      { readonly __typename: 'User' }
      & Pick<User, 'id' | 'name'>
    ) }
  )>, readonly self: (
    { readonly __typename: 'User' }
    & Pick<User, 'isGlobalAdministrator'>
    & SelfFieldsFragment
  ) }
);

export type CreateStationMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStationMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createStation: (
    { readonly __typename: 'Station' }
    & Pick<Station, 'id'>
  ) }
);

export type UserEditFieldsFragment = (
  { readonly __typename: 'User' }
  & Pick<User, 'id' | 'isDisabled' | 'ssn' | 'name' | 'email'>
  & { readonly globalRole: (
    { readonly __typename: 'GlobalRole' }
    & Pick<GlobalRole, 'id'>
  ) }
);

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserQuery = (
  { readonly __typename: 'Query' }
  & { readonly user: (
    { readonly __typename: 'User' }
    & UserEditFieldsFragment
  ), readonly globalRoles: ReadonlyArray<(
    { readonly __typename: 'GlobalRole' }
    & Pick<GlobalRole, 'id' | 'label'>
  )>, readonly self: (
    { readonly __typename: 'User' }
    & Pick<User, 'isGlobalModerator'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UserUpdateInput;
}>;


export type UpdateUserMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateUser: (
    { readonly __typename: 'User' }
    & UserEditFieldsFragment
  ) }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { readonly __typename: 'Query' }
  & { readonly users: ReadonlyArray<(
    { readonly __typename: 'User' }
    & Pick<User, 'id' | 'latestLoginAt' | 'ssn' | 'name'>
    & { readonly globalRole: (
      { readonly __typename: 'GlobalRole' }
      & Pick<GlobalRole, 'id' | 'label'>
    ), readonly editorForStations: ReadonlyArray<(
      { readonly __typename: 'Station' }
      & Pick<Station, 'id' | 'title'>
    )> }
  )>, readonly self: (
    { readonly __typename: 'User' }
    & Pick<User, 'isGlobalModerator'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  ssn: Scalars['String'];
}>;


export type CreateUserMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createUser: (
    { readonly __typename: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type ActionableEditFieldsFragment = (
  { readonly __typename: 'Actionable' }
  & Pick<Actionable, 'id' | 'value' | 'prefix' | 'isPublished'>
  & { readonly kind: (
    { readonly __typename: 'ActionableKindModel' }
    & Pick<ActionableKindModel, 'id' | 'label' | 'description'>
  ) }
);

export type ActionableQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActionableQuery = (
  { readonly __typename: 'Query' }
  & { readonly actionable: (
    { readonly __typename: 'Actionable' }
    & ActionableEditFieldsFragment
  ), readonly actionableKinds: ReadonlyArray<(
    { readonly __typename: 'ActionableKindModel' }
    & Pick<ActionableKindModel, 'id' | 'label' | 'description' | 'disabled'>
  )> }
);

export type CreateReferenceForFirstDuplicateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateReferenceForFirstDuplicateMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createReferenceForFirstDuplicate: (
    { readonly __typename: 'Actionable' }
    & ActionableEditFieldsFragment
  ) }
);

export type CreateActionableAndAttachMutationVariables = Exact<{
  entryId: Scalars['Int'];
  kindId: ActionableKindId;
}>;


export type CreateActionableAndAttachMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createActionableAndAttach: (
    { readonly __typename: 'CreateActionableAndAttachOutput' }
    & { readonly entry: (
      { readonly __typename: 'Entry' }
      & { readonly actionables: ReadonlyArray<(
        { readonly __typename: 'Actionable' }
        & ActionableEditFieldsFragment
      )> }
      & EntryEditFieldsFragment
    ), readonly actionable: (
      { readonly __typename: 'Actionable' }
      & ActionableEditFieldsFragment
    ) }
  ) }
);

export type UpdateActionableMutationVariables = Exact<{
  id: Scalars['Int'];
  input: ActionableUpdateInput;
}>;


export type UpdateActionableMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateActionable: (
    { readonly __typename: 'ActionableUpdateOutput' }
    & Pick<ActionableUpdateOutput, 'validationError'>
    & { readonly actionable: (
      { readonly __typename: 'Actionable' }
      & ActionableEditFieldsFragment
    ), readonly matchedEntry: Maybe<(
      { readonly __typename: 'Entry' }
      & ListEntryFieldsFragment
    )> }
  ) }
);

export type DeleteActionableMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteActionableMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteActionable: (
    { readonly __typename: 'Entry' }
    & { readonly actionables: ReadonlyArray<(
      { readonly __typename: 'Actionable' }
      & ActionableEditFieldsFragment
    )> }
    & EntryEditFieldsFragment
  ) }
);

export type StationEditFieldsFragment = (
  { readonly __typename: 'Station' }
  & Pick<Station, 'id' | 'isPublished' | 'orgsWantData' | 'lshDepartments' | 'prefix' | 'title' | 'description' | 'userModeratorId'>
  & { readonly editors: ReadonlyArray<(
    { readonly __typename: 'User' }
    & Pick<User, 'id'>
  )>, readonly showEntryDescription: (
    { readonly __typename: 'StationShowEntryDescription' }
    & Pick<StationShowEntryDescription, 'id'>
  ) }
);

export type EditStationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EditStationQuery = (
  { readonly __typename: 'Query' }
  & { readonly station: (
    { readonly __typename: 'Station' }
    & Pick<Station, 'canSelfEdit'>
    & StationEditFieldsFragment
  ), readonly self: (
    { readonly __typename: 'User' }
    & SelfFieldsFragment
  ), readonly showEntryDescriptions: ReadonlyArray<(
    { readonly __typename: 'StationShowEntryDescription' }
    & Pick<StationShowEntryDescription, 'id' | 'label'>
  )>, readonly allUsers: ReadonlyArray<(
    { readonly __typename: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type UpdateStationMutationVariables = Exact<{
  id: Scalars['Int'];
  input: StationUpdateInput;
}>;


export type UpdateStationMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateStation: (
    { readonly __typename: 'Station' }
    & StationEditFieldsFragment
  ) }
);

export type DeleteStationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStationMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'deleteStation'>
);

export type SortCategoryEntriesMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SortCategoryEntriesMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly sortCategoryEntries: (
    { readonly __typename: 'Category' }
    & Pick<Category, 'id' | 'index'>
  ) }
);

export type AttachEntryToCategoryMutationVariables = Exact<{
  entryId: Scalars['Int'];
  categoryId: Scalars['Int'];
}>;


export type AttachEntryToCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'attachEntryToCategory'>
);

export type UpdateCategorisedEntryOrderMutationVariables = Exact<{
  categoryId: Scalars['Int'];
  dragEntryId: Scalars['Int'];
  dropEntryId: Scalars['Int'];
}>;


export type UpdateCategorisedEntryOrderMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateCategorisedEntryOrder: ReadonlyArray<(
    { readonly __typename: 'EntryInCategory' }
    & Pick<EntryInCategory, 'id'>
  )> }
);

export type CategoryEditFieldsFragment = (
  { readonly __typename: 'Category' }
  & Pick<Category, 'id' | 'fcolor' | 'bcolor'>
  & { title: Category['title'] }
);

export type CategoryEditQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CategoryEditQuery = (
  { readonly __typename: 'Query' }
  & { readonly category: (
    { readonly __typename: 'Category' }
    & CategoryEditFieldsFragment
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  input: CategoryUpdateInput;
}>;


export type UpdateCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateCategory: (
    { readonly __typename: 'Category' }
    & Pick<Category, 'id' | 'bcolor' | 'fcolor' | 'title'>
  ) }
);

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'deleteCategory'>
);

export type EntryEditFieldsFragment = (
  { readonly __typename: 'Entry' }
  & Pick<Entry, 'id' | 'expiresAt' | 'prefix' | 'dingaloTitle' | 'title' | 'description' | 'keyWords' | 'hasUnpublishedActionables'>
  & { readonly station: (
    { readonly __typename: 'Station' }
    & Pick<Station, 'id'>
  ) }
);

export type EntryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EntryQuery = (
  { readonly __typename: 'Query' }
  & { readonly entry: (
    { readonly __typename: 'Entry' }
    & Pick<Entry, 'createdAt' | 'updatedAt'>
    & { canSelfEditEntry: Entry['canSelfEdit'] }
    & { readonly updatedBy: (
      { readonly __typename: 'User' }
      & Pick<User, 'id' | 'name'>
    ), readonly station: (
      { readonly __typename: 'Station' }
      & Pick<Station, 'id' | 'title'>
    ), readonly categorisedInStations: Maybe<ReadonlyArray<(
      { readonly __typename: 'StationEntryInCategory' }
      & Pick<StationEntryInCategory, 'id' | 'title' | 'entryInCategoryId' | 'altTitle' | 'categoryTitle'>
    )>>, readonly actionables: ReadonlyArray<(
      { readonly __typename: 'Actionable' }
      & ActionableEditFieldsFragment
    )> }
    & EntryEditFieldsFragment
  ), readonly actionableKinds: ReadonlyArray<(
    { readonly __typename: 'ActionableKindModel' }
    & Pick<ActionableKindModel, 'id' | 'label' | 'description'>
  )>, readonly stations: ReadonlyArray<(
    { readonly __typename: 'Station' }
    & Pick<Station, 'id' | 'prefix' | 'title'>
  )> }
);

export type UpdateEntryMutationVariables = Exact<{
  id: Scalars['Int'];
  input: EntryUpdateInput;
}>;


export type UpdateEntryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateEntry: (
    { readonly __typename: 'Entry' }
    & EntryEditFieldsFragment
  ) }
);

export type DeleteEntryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEntryMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'deleteEntry'>
);

export type EntryInCategoryEditFieldsFragment = (
  { readonly __typename: 'EntryInCategory' }
  & Pick<EntryInCategory, 'id' | 'altTitle' | 'entryId'>
  & { readonly category: (
    { readonly __typename: 'Category' }
    & Pick<Category, 'id' | 'title'>
  ) }
);

export type UpdateEntryInCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  input: EntryInCategoryUpdateInput;
}>;


export type UpdateEntryInCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateEntryInCategory: (
    { readonly __typename: 'EntryInCategory' }
    & Pick<EntryInCategory, 'id' | 'altTitle'>
  ) }
);

export type DeleteEntryInCategoryMutationVariables = Exact<{
  dingaloId: Scalars['Int'];
}>;


export type DeleteEntryInCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'deleteEntryInCategory'>
);

export type SearchOtherStationEntriesQueryVariables = Exact<{
  value: Maybe<Scalars['String']>;
  excludeStationId: Scalars['Int'];
}>;


export type SearchOtherStationEntriesQuery = (
  { readonly __typename: 'Query' }
  & { readonly entries: ReadonlyArray<(
    { readonly __typename: 'Entry' }
    & Pick<Entry, 'id' | 'prefix' | 'title' | 'dingaloTitle'>
    & { readonly station: (
      { readonly __typename: 'Station' }
      & Pick<Station, 'id' | 'title'>
    ), readonly actionables: ReadonlyArray<(
      { readonly __typename: 'Actionable' }
      & Pick<Actionable, 'id' | 'value' | 'prefix'>
      & { readonly kind: (
        { readonly __typename: 'ActionableKindModel' }
        & Pick<ActionableKindModel, 'id'>
      ) }
    )> }
  )> }
);

export type StationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StationQuery = (
  { readonly __typename: 'Query' }
  & { readonly station: (
    { readonly __typename: 'Station' }
    & Pick<Station, 'id' | 'isPublished' | 'canSelfEdit' | 'prefix' | 'title'>
    & { readonly editors: ReadonlyArray<(
      { readonly __typename: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  ), readonly allStations: ReadonlyArray<(
    { readonly __typename: 'Station' }
    & Pick<Station, 'id' | 'prefix' | 'title'>
  )> }
);

export type ListEntryFieldsFragment = (
  { readonly __typename: 'Entry' }
  & Pick<Entry, 'id' | 'prefix' | 'title' | 'dingaloTitle'>
  & { readonly actionables: ReadonlyArray<(
    { readonly __typename: 'Actionable' }
    & Pick<Actionable, 'id' | 'value' | 'prefix'>
    & { readonly kind: (
      { readonly __typename: 'ActionableKindModel' }
      & Pick<ActionableKindModel, 'id'>
    ) }
  )> }
);

export type StationUncategorizedEntriesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StationUncategorizedEntriesQuery = (
  { readonly __typename: 'Query' }
  & { readonly station: (
    { readonly __typename: 'Station' }
    & Pick<Station, 'id'>
    & { readonly nonCategorisedEntries: ReadonlyArray<(
      { readonly __typename: 'Entry' }
      & ListEntryFieldsFragment
    )> }
  ) }
);

export type StationCategorizedEntriesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StationCategorizedEntriesQuery = (
  { readonly __typename: 'Query' }
  & { readonly station: (
    { readonly __typename: 'Station' }
    & Pick<Station, 'id'>
    & { readonly categories: ReadonlyArray<(
      { readonly __typename: 'Category' }
      & Pick<Category, 'id' | 'fcolor' | 'bcolor' | 'index' | 'title'>
      & { readonly dingaloes: ReadonlyArray<(
        { readonly __typename: 'EntryInCategory' }
        & Pick<EntryInCategory, 'id' | 'categoryId' | 'isBorrowed' | 'altTitle'>
        & { readonly entry: (
          { readonly __typename: 'Entry' }
          & Pick<Entry, 'hasUnpublishedActionables'>
          & ListEntryFieldsFragment
        ) }
      )> }
    )> }
  ) }
);

export type OtherStationCategorizedEntriesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OtherStationCategorizedEntriesQuery = (
  { readonly __typename: 'Query' }
  & { readonly station: (
    { readonly __typename: 'Station' }
    & Pick<Station, 'id' | 'prefix' | 'title'>
    & { readonly categories: ReadonlyArray<(
      { readonly __typename: 'Category' }
      & Pick<Category, 'id' | 'title'>
      & { readonly dingaloes: ReadonlyArray<(
        { readonly __typename: 'EntryInCategory' }
        & Pick<EntryInCategory, 'id'>
        & { readonly entry: (
          { readonly __typename: 'Entry' }
          & Pick<Entry, 'dingaloTitle'>
          & ListEntryFieldsFragment
        ) }
      )> }
    )> }
  ) }
);

export type CreateCategoryMutationVariables = Exact<{
  stationId: Scalars['Int'];
}>;


export type CreateCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createCategory: (
    { readonly __typename: 'Category' }
    & Pick<Category, 'id'>
  ) }
);

export type ReorderCategoryMutationVariables = Exact<{
  dragCategoryId: Scalars['Int'];
  dropCategoryId: Scalars['Int'];
}>;


export type ReorderCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly reorderCategory: ReadonlyArray<(
    { readonly __typename: 'Category' }
    & Pick<Category, 'id'>
  )> }
);

export type CreateEntryMutationVariables = Exact<{
  stationId: Scalars['Int'];
}>;


export type CreateEntryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createEntry: (
    { readonly __typename: 'Entry' }
    & Pick<Entry, 'id'>
  ) }
);

export const SelfFieldsFragmentDoc = gql`
    fragment SelfFields on User {
  id
  name
}
    `;
export const UserEditFieldsFragmentDoc = gql`
    fragment UserEditFields on User {
  id
  isDisabled
  ssn
  name
  email
  globalRole {
    id
  }
}
    `;
export const ActionableEditFieldsFragmentDoc = gql`
    fragment ActionableEditFields on Actionable {
  id
  value
  prefix
  isPublished
  kind {
    id
    label
    description
  }
}
    `;
export const StationEditFieldsFragmentDoc = gql`
    fragment StationEditFields on Station {
  id
  isPublished
  orgsWantData
  lshDepartments
  prefix
  title
  description
  userModeratorId
  editors {
    id
  }
  showEntryDescription {
    id
  }
}
    `;
export const CategoryEditFieldsFragmentDoc = gql`
    fragment CategoryEditFields on Category {
  id
  title: title(raw: true)
  fcolor
  bcolor
}
    `;
export const EntryEditFieldsFragmentDoc = gql`
    fragment EntryEditFields on Entry {
  id
  expiresAt
  prefix
  dingaloTitle
  title
  description
  keyWords
  prefix
  hasUnpublishedActionables
  station {
    id
  }
}
    `;
export const EntryInCategoryEditFieldsFragmentDoc = gql`
    fragment EntryInCategoryEditFields on EntryInCategory {
  id
  altTitle
  entryId
  category {
    id
    title
  }
}
    `;
export const ListEntryFieldsFragmentDoc = gql`
    fragment ListEntryFields on Entry {
  id
  prefix
  title
  dingaloTitle
  actionables {
    id
    kind {
      id
    }
    value
    prefix
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($ssn: String!, $password: String!) {
  logIn(ssn: $ssn, password: $password) {
    accessToken
  }
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      ssn: // value for 'ssn'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UserScreenDocument = gql`
    query UserScreen {
  self {
    globalRole {
      id
      label
    }
    appBuildInfo
    ...SelfFields
  }
  globalRoles {
    id
    label
    description
  }
}
    ${SelfFieldsFragmentDoc}`;

/**
 * __useUserScreenQuery__
 *
 * To run a query within a React component, call `useUserScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserScreenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserScreenQuery, UserScreenQueryVariables>) {
        return ApolloReactHooks.useQuery<UserScreenQuery, UserScreenQueryVariables>(UserScreenDocument, baseOptions);
      }
export function useUserScreenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserScreenQuery, UserScreenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserScreenQuery, UserScreenQueryVariables>(UserScreenDocument, baseOptions);
        }
export type UserScreenQueryHookResult = ReturnType<typeof useUserScreenQuery>;
export type UserScreenLazyQueryHookResult = ReturnType<typeof useUserScreenLazyQuery>;
export type UserScreenQueryResult = ApolloReactCommon.QueryResult<UserScreenQuery, UserScreenQueryVariables>;
export const StationsDocument = gql`
    query Stations {
  stations {
    id
    updatedAt
    prefix
    title
    isPublished
    canSelfEdit
    userModerator {
      id
      name
    }
  }
  self {
    isGlobalAdministrator
    ...SelfFields
  }
}
    ${SelfFieldsFragmentDoc}`;

/**
 * __useStationsQuery__
 *
 * To run a query within a React component, call `useStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StationsQuery, StationsQueryVariables>) {
        return ApolloReactHooks.useQuery<StationsQuery, StationsQueryVariables>(StationsDocument, baseOptions);
      }
export function useStationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StationsQuery, StationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StationsQuery, StationsQueryVariables>(StationsDocument, baseOptions);
        }
export type StationsQueryHookResult = ReturnType<typeof useStationsQuery>;
export type StationsLazyQueryHookResult = ReturnType<typeof useStationsLazyQuery>;
export type StationsQueryResult = ApolloReactCommon.QueryResult<StationsQuery, StationsQueryVariables>;
export const CreateStationDocument = gql`
    mutation CreateStation {
  createStation {
    id
  }
}
    `;

/**
 * __useCreateStationMutation__
 *
 * To run a mutation, you first call `useCreateStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStationMutation, { data, loading, error }] = useCreateStationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStationMutation, CreateStationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStationMutation, CreateStationMutationVariables>(CreateStationDocument, baseOptions);
      }
export type CreateStationMutationHookResult = ReturnType<typeof useCreateStationMutation>;
export type CreateStationMutationResult = ApolloReactCommon.MutationResult<CreateStationMutation>;
export type CreateStationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStationMutation, CreateStationMutationVariables>;
export const UserDocument = gql`
    query User($id: Int!) {
  user(id: $id) {
    ...UserEditFields
  }
  globalRoles {
    id
    label
  }
  self {
    isGlobalModerator
  }
}
    ${UserEditFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $input: UserUpdateInput!) {
  updateUser(id: $id, input: $input) {
    ...UserEditFields
  }
}
    ${UserEditFieldsFragmentDoc}`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    latestLoginAt
    ssn
    name
    globalRole {
      id
      label
    }
    editorForStations {
      id
      title
    }
  }
  self {
    isGlobalModerator
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($ssn: String!) {
  createUser(ssn: $ssn) {
    id
  }
}
    `;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      ssn: // value for 'ssn'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Int!) {
  deleteUser(id: $id)
}
    `;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ActionableDocument = gql`
    query Actionable($id: Int!) {
  actionable(id: $id) {
    ...ActionableEditFields
  }
  actionableKinds {
    id
    label
    description
    disabled
  }
}
    ${ActionableEditFieldsFragmentDoc}`;

/**
 * __useActionableQuery__
 *
 * To run a query within a React component, call `useActionableQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActionableQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ActionableQuery, ActionableQueryVariables>) {
        return ApolloReactHooks.useQuery<ActionableQuery, ActionableQueryVariables>(ActionableDocument, baseOptions);
      }
export function useActionableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActionableQuery, ActionableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActionableQuery, ActionableQueryVariables>(ActionableDocument, baseOptions);
        }
export type ActionableQueryHookResult = ReturnType<typeof useActionableQuery>;
export type ActionableLazyQueryHookResult = ReturnType<typeof useActionableLazyQuery>;
export type ActionableQueryResult = ApolloReactCommon.QueryResult<ActionableQuery, ActionableQueryVariables>;
export const CreateReferenceForFirstDuplicateDocument = gql`
    mutation CreateReferenceForFirstDuplicate($id: Int!) {
  createReferenceForFirstDuplicate(id: $id) {
    ...ActionableEditFields
  }
}
    ${ActionableEditFieldsFragmentDoc}`;

/**
 * __useCreateReferenceForFirstDuplicateMutation__
 *
 * To run a mutation, you first call `useCreateReferenceForFirstDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceForFirstDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceForFirstDuplicateMutation, { data, loading, error }] = useCreateReferenceForFirstDuplicateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateReferenceForFirstDuplicateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReferenceForFirstDuplicateMutation, CreateReferenceForFirstDuplicateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReferenceForFirstDuplicateMutation, CreateReferenceForFirstDuplicateMutationVariables>(CreateReferenceForFirstDuplicateDocument, baseOptions);
      }
export type CreateReferenceForFirstDuplicateMutationHookResult = ReturnType<typeof useCreateReferenceForFirstDuplicateMutation>;
export type CreateReferenceForFirstDuplicateMutationResult = ApolloReactCommon.MutationResult<CreateReferenceForFirstDuplicateMutation>;
export type CreateReferenceForFirstDuplicateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReferenceForFirstDuplicateMutation, CreateReferenceForFirstDuplicateMutationVariables>;
export const CreateActionableAndAttachDocument = gql`
    mutation CreateActionableAndAttach($entryId: Int!, $kindId: ActionableKindId!) {
  createActionableAndAttach(entryId: $entryId, kindId: $kindId) {
    entry {
      ...EntryEditFields
      actionables {
        ...ActionableEditFields
      }
    }
    actionable {
      ...ActionableEditFields
    }
  }
}
    ${EntryEditFieldsFragmentDoc}
${ActionableEditFieldsFragmentDoc}`;

/**
 * __useCreateActionableAndAttachMutation__
 *
 * To run a mutation, you first call `useCreateActionableAndAttachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionableAndAttachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionableAndAttachMutation, { data, loading, error }] = useCreateActionableAndAttachMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      kindId: // value for 'kindId'
 *   },
 * });
 */
export function useCreateActionableAndAttachMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateActionableAndAttachMutation, CreateActionableAndAttachMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateActionableAndAttachMutation, CreateActionableAndAttachMutationVariables>(CreateActionableAndAttachDocument, baseOptions);
      }
export type CreateActionableAndAttachMutationHookResult = ReturnType<typeof useCreateActionableAndAttachMutation>;
export type CreateActionableAndAttachMutationResult = ApolloReactCommon.MutationResult<CreateActionableAndAttachMutation>;
export type CreateActionableAndAttachMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateActionableAndAttachMutation, CreateActionableAndAttachMutationVariables>;
export const UpdateActionableDocument = gql`
    mutation UpdateActionable($id: Int!, $input: ActionableUpdateInput!) {
  updateActionable(id: $id, input: $input) {
    actionable {
      ...ActionableEditFields
    }
    validationError
    matchedEntry {
      ...ListEntryFields
    }
  }
}
    ${ActionableEditFieldsFragmentDoc}
${ListEntryFieldsFragmentDoc}`;

/**
 * __useUpdateActionableMutation__
 *
 * To run a mutation, you first call `useUpdateActionableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionableMutation, { data, loading, error }] = useUpdateActionableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActionableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateActionableMutation, UpdateActionableMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateActionableMutation, UpdateActionableMutationVariables>(UpdateActionableDocument, baseOptions);
      }
export type UpdateActionableMutationHookResult = ReturnType<typeof useUpdateActionableMutation>;
export type UpdateActionableMutationResult = ApolloReactCommon.MutationResult<UpdateActionableMutation>;
export type UpdateActionableMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateActionableMutation, UpdateActionableMutationVariables>;
export const DeleteActionableDocument = gql`
    mutation DeleteActionable($id: Int!) {
  deleteActionable(id: $id) {
    ...EntryEditFields
    actionables {
      ...ActionableEditFields
    }
  }
}
    ${EntryEditFieldsFragmentDoc}
${ActionableEditFieldsFragmentDoc}`;

/**
 * __useDeleteActionableMutation__
 *
 * To run a mutation, you first call `useDeleteActionableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionableMutation, { data, loading, error }] = useDeleteActionableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActionableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteActionableMutation, DeleteActionableMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteActionableMutation, DeleteActionableMutationVariables>(DeleteActionableDocument, baseOptions);
      }
export type DeleteActionableMutationHookResult = ReturnType<typeof useDeleteActionableMutation>;
export type DeleteActionableMutationResult = ApolloReactCommon.MutationResult<DeleteActionableMutation>;
export type DeleteActionableMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteActionableMutation, DeleteActionableMutationVariables>;
export const EditStationDocument = gql`
    query EditStation($id: Int!) {
  station(id: $id) {
    ...StationEditFields
    canSelfEdit
  }
  self {
    ...SelfFields
  }
  showEntryDescriptions {
    id
    label
  }
  allUsers: users {
    id
    name
  }
}
    ${StationEditFieldsFragmentDoc}
${SelfFieldsFragmentDoc}`;

/**
 * __useEditStationQuery__
 *
 * To run a query within a React component, call `useEditStationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditStationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditStationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditStationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EditStationQuery, EditStationQueryVariables>) {
        return ApolloReactHooks.useQuery<EditStationQuery, EditStationQueryVariables>(EditStationDocument, baseOptions);
      }
export function useEditStationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditStationQuery, EditStationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditStationQuery, EditStationQueryVariables>(EditStationDocument, baseOptions);
        }
export type EditStationQueryHookResult = ReturnType<typeof useEditStationQuery>;
export type EditStationLazyQueryHookResult = ReturnType<typeof useEditStationLazyQuery>;
export type EditStationQueryResult = ApolloReactCommon.QueryResult<EditStationQuery, EditStationQueryVariables>;
export const UpdateStationDocument = gql`
    mutation UpdateStation($id: Int!, $input: StationUpdateInput!) {
  updateStation(id: $id, input: $input) {
    ...StationEditFields
  }
}
    ${StationEditFieldsFragmentDoc}`;

/**
 * __useUpdateStationMutation__
 *
 * To run a mutation, you first call `useUpdateStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStationMutation, { data, loading, error }] = useUpdateStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStationMutation, UpdateStationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStationMutation, UpdateStationMutationVariables>(UpdateStationDocument, baseOptions);
      }
export type UpdateStationMutationHookResult = ReturnType<typeof useUpdateStationMutation>;
export type UpdateStationMutationResult = ApolloReactCommon.MutationResult<UpdateStationMutation>;
export type UpdateStationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStationMutation, UpdateStationMutationVariables>;
export const DeleteStationDocument = gql`
    mutation DeleteStation($id: Int!) {
  deleteStation(id: $id)
}
    `;

/**
 * __useDeleteStationMutation__
 *
 * To run a mutation, you first call `useDeleteStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStationMutation, { data, loading, error }] = useDeleteStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStationMutation, DeleteStationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStationMutation, DeleteStationMutationVariables>(DeleteStationDocument, baseOptions);
      }
export type DeleteStationMutationHookResult = ReturnType<typeof useDeleteStationMutation>;
export type DeleteStationMutationResult = ApolloReactCommon.MutationResult<DeleteStationMutation>;
export type DeleteStationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStationMutation, DeleteStationMutationVariables>;
export const SortCategoryEntriesDocument = gql`
    mutation SortCategoryEntries($id: Int!) {
  sortCategoryEntries(id: $id) {
    id
    index
  }
}
    `;

/**
 * __useSortCategoryEntriesMutation__
 *
 * To run a mutation, you first call `useSortCategoryEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortCategoryEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortCategoryEntriesMutation, { data, loading, error }] = useSortCategoryEntriesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSortCategoryEntriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SortCategoryEntriesMutation, SortCategoryEntriesMutationVariables>) {
        return ApolloReactHooks.useMutation<SortCategoryEntriesMutation, SortCategoryEntriesMutationVariables>(SortCategoryEntriesDocument, baseOptions);
      }
export type SortCategoryEntriesMutationHookResult = ReturnType<typeof useSortCategoryEntriesMutation>;
export type SortCategoryEntriesMutationResult = ApolloReactCommon.MutationResult<SortCategoryEntriesMutation>;
export type SortCategoryEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<SortCategoryEntriesMutation, SortCategoryEntriesMutationVariables>;
export const AttachEntryToCategoryDocument = gql`
    mutation AttachEntryToCategory($entryId: Int!, $categoryId: Int!) {
  attachEntryToCategory(entryId: $entryId, categoryId: $categoryId)
}
    `;

/**
 * __useAttachEntryToCategoryMutation__
 *
 * To run a mutation, you first call `useAttachEntryToCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachEntryToCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachEntryToCategoryMutation, { data, loading, error }] = useAttachEntryToCategoryMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useAttachEntryToCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachEntryToCategoryMutation, AttachEntryToCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachEntryToCategoryMutation, AttachEntryToCategoryMutationVariables>(AttachEntryToCategoryDocument, baseOptions);
      }
export type AttachEntryToCategoryMutationHookResult = ReturnType<typeof useAttachEntryToCategoryMutation>;
export type AttachEntryToCategoryMutationResult = ApolloReactCommon.MutationResult<AttachEntryToCategoryMutation>;
export type AttachEntryToCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachEntryToCategoryMutation, AttachEntryToCategoryMutationVariables>;
export const UpdateCategorisedEntryOrderDocument = gql`
    mutation UpdateCategorisedEntryOrder($categoryId: Int!, $dragEntryId: Int!, $dropEntryId: Int!) {
  updateCategorisedEntryOrder(
    categoryId: $categoryId
    dragEntryId: $dragEntryId
    dropEntryId: $dropEntryId
  ) {
    id
  }
}
    `;

/**
 * __useUpdateCategorisedEntryOrderMutation__
 *
 * To run a mutation, you first call `useUpdateCategorisedEntryOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategorisedEntryOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategorisedEntryOrderMutation, { data, loading, error }] = useUpdateCategorisedEntryOrderMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      dragEntryId: // value for 'dragEntryId'
 *      dropEntryId: // value for 'dropEntryId'
 *   },
 * });
 */
export function useUpdateCategorisedEntryOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCategorisedEntryOrderMutation, UpdateCategorisedEntryOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCategorisedEntryOrderMutation, UpdateCategorisedEntryOrderMutationVariables>(UpdateCategorisedEntryOrderDocument, baseOptions);
      }
export type UpdateCategorisedEntryOrderMutationHookResult = ReturnType<typeof useUpdateCategorisedEntryOrderMutation>;
export type UpdateCategorisedEntryOrderMutationResult = ApolloReactCommon.MutationResult<UpdateCategorisedEntryOrderMutation>;
export type UpdateCategorisedEntryOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCategorisedEntryOrderMutation, UpdateCategorisedEntryOrderMutationVariables>;
export const CategoryEditDocument = gql`
    query CategoryEdit($id: Int!) {
  category(id: $id) {
    ...CategoryEditFields
  }
}
    ${CategoryEditFieldsFragmentDoc}`;

/**
 * __useCategoryEditQuery__
 *
 * To run a query within a React component, call `useCategoryEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryEditQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
      }
export function useCategoryEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
        }
export type CategoryEditQueryHookResult = ReturnType<typeof useCategoryEditQuery>;
export type CategoryEditLazyQueryHookResult = ReturnType<typeof useCategoryEditLazyQuery>;
export type CategoryEditQueryResult = ApolloReactCommon.QueryResult<CategoryEditQuery, CategoryEditQueryVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: Int!, $input: CategoryUpdateInput!) {
  updateCategory(id: $id, input: $input) {
    id
    bcolor
    fcolor
    title
  }
}
    `;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, baseOptions);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: Int!) {
  deleteCategory(id: $id)
}
    `;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, baseOptions);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const EntryDocument = gql`
    query Entry($id: Int!) {
  entry(id: $id) {
    canSelfEditEntry: canSelfEdit
    ...EntryEditFields
    createdAt
    updatedAt
    updatedBy {
      id
      name
    }
    station {
      id
      title
    }
    categorisedInStations {
      id
      title
      entryInCategoryId
      altTitle
      categoryTitle
    }
    actionables {
      ...ActionableEditFields
    }
  }
  actionableKinds {
    id
    label
    description
  }
  stations {
    id
    prefix
    title
  }
}
    ${EntryEditFieldsFragmentDoc}
${ActionableEditFieldsFragmentDoc}`;

/**
 * __useEntryQuery__
 *
 * To run a query within a React component, call `useEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EntryQuery, EntryQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryQuery, EntryQueryVariables>(EntryDocument, baseOptions);
      }
export function useEntryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryQuery, EntryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryQuery, EntryQueryVariables>(EntryDocument, baseOptions);
        }
export type EntryQueryHookResult = ReturnType<typeof useEntryQuery>;
export type EntryLazyQueryHookResult = ReturnType<typeof useEntryLazyQuery>;
export type EntryQueryResult = ApolloReactCommon.QueryResult<EntryQuery, EntryQueryVariables>;
export const UpdateEntryDocument = gql`
    mutation UpdateEntry($id: Int!, $input: EntryUpdateInput!) {
  updateEntry(id: $id, input: $input) {
    ...EntryEditFields
  }
}
    ${EntryEditFieldsFragmentDoc}`;

/**
 * __useUpdateEntryMutation__
 *
 * To run a mutation, you first call `useUpdateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryMutation, { data, loading, error }] = useUpdateEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntryMutation, UpdateEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntryMutation, UpdateEntryMutationVariables>(UpdateEntryDocument, baseOptions);
      }
export type UpdateEntryMutationHookResult = ReturnType<typeof useUpdateEntryMutation>;
export type UpdateEntryMutationResult = ApolloReactCommon.MutationResult<UpdateEntryMutation>;
export type UpdateEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntryMutation, UpdateEntryMutationVariables>;
export const DeleteEntryDocument = gql`
    mutation DeleteEntry($id: Int!) {
  deleteEntry(id: $id)
}
    `;

/**
 * __useDeleteEntryMutation__
 *
 * To run a mutation, you first call `useDeleteEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntryMutation, { data, loading, error }] = useDeleteEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEntryMutation, DeleteEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEntryMutation, DeleteEntryMutationVariables>(DeleteEntryDocument, baseOptions);
      }
export type DeleteEntryMutationHookResult = ReturnType<typeof useDeleteEntryMutation>;
export type DeleteEntryMutationResult = ApolloReactCommon.MutationResult<DeleteEntryMutation>;
export type DeleteEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEntryMutation, DeleteEntryMutationVariables>;
export const UpdateEntryInCategoryDocument = gql`
    mutation UpdateEntryInCategory($id: Int!, $input: EntryInCategoryUpdateInput!) {
  updateEntryInCategory(id: $id, input: $input) {
    id
    altTitle
  }
}
    `;

/**
 * __useUpdateEntryInCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateEntryInCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryInCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryInCategoryMutation, { data, loading, error }] = useUpdateEntryInCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntryInCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntryInCategoryMutation, UpdateEntryInCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntryInCategoryMutation, UpdateEntryInCategoryMutationVariables>(UpdateEntryInCategoryDocument, baseOptions);
      }
export type UpdateEntryInCategoryMutationHookResult = ReturnType<typeof useUpdateEntryInCategoryMutation>;
export type UpdateEntryInCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateEntryInCategoryMutation>;
export type UpdateEntryInCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntryInCategoryMutation, UpdateEntryInCategoryMutationVariables>;
export const DeleteEntryInCategoryDocument = gql`
    mutation DeleteEntryInCategory($dingaloId: Int!) {
  deleteEntryInCategory(dingaloId: $dingaloId)
}
    `;

/**
 * __useDeleteEntryInCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteEntryInCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntryInCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntryInCategoryMutation, { data, loading, error }] = useDeleteEntryInCategoryMutation({
 *   variables: {
 *      dingaloId: // value for 'dingaloId'
 *   },
 * });
 */
export function useDeleteEntryInCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEntryInCategoryMutation, DeleteEntryInCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEntryInCategoryMutation, DeleteEntryInCategoryMutationVariables>(DeleteEntryInCategoryDocument, baseOptions);
      }
export type DeleteEntryInCategoryMutationHookResult = ReturnType<typeof useDeleteEntryInCategoryMutation>;
export type DeleteEntryInCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteEntryInCategoryMutation>;
export type DeleteEntryInCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEntryInCategoryMutation, DeleteEntryInCategoryMutationVariables>;
export const SearchOtherStationEntriesDocument = gql`
    query SearchOtherStationEntries($value: String, $excludeStationId: Int!) {
  entries: searchOtherStationEntries(
    value: $value
    excludeStationId: $excludeStationId
  ) {
    id
    prefix
    title
    dingaloTitle
    station {
      id
      title
    }
    actionables {
      id
      kind {
        id
      }
      value
      prefix
    }
  }
}
    `;

/**
 * __useSearchOtherStationEntriesQuery__
 *
 * To run a query within a React component, call `useSearchOtherStationEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOtherStationEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOtherStationEntriesQuery({
 *   variables: {
 *      value: // value for 'value'
 *      excludeStationId: // value for 'excludeStationId'
 *   },
 * });
 */
export function useSearchOtherStationEntriesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchOtherStationEntriesQuery, SearchOtherStationEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchOtherStationEntriesQuery, SearchOtherStationEntriesQueryVariables>(SearchOtherStationEntriesDocument, baseOptions);
      }
export function useSearchOtherStationEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchOtherStationEntriesQuery, SearchOtherStationEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchOtherStationEntriesQuery, SearchOtherStationEntriesQueryVariables>(SearchOtherStationEntriesDocument, baseOptions);
        }
export type SearchOtherStationEntriesQueryHookResult = ReturnType<typeof useSearchOtherStationEntriesQuery>;
export type SearchOtherStationEntriesLazyQueryHookResult = ReturnType<typeof useSearchOtherStationEntriesLazyQuery>;
export type SearchOtherStationEntriesQueryResult = ApolloReactCommon.QueryResult<SearchOtherStationEntriesQuery, SearchOtherStationEntriesQueryVariables>;
export const StationDocument = gql`
    query Station($id: Int!) {
  station(id: $id) {
    id
    isPublished
    canSelfEdit
    prefix
    title
    editors {
      id
      name
    }
  }
  allStations: stations {
    id
    prefix
    title
  }
}
    `;

/**
 * __useStationQuery__
 *
 * To run a query within a React component, call `useStationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StationQuery, StationQueryVariables>) {
        return ApolloReactHooks.useQuery<StationQuery, StationQueryVariables>(StationDocument, baseOptions);
      }
export function useStationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StationQuery, StationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StationQuery, StationQueryVariables>(StationDocument, baseOptions);
        }
export type StationQueryHookResult = ReturnType<typeof useStationQuery>;
export type StationLazyQueryHookResult = ReturnType<typeof useStationLazyQuery>;
export type StationQueryResult = ApolloReactCommon.QueryResult<StationQuery, StationQueryVariables>;
export const StationUncategorizedEntriesDocument = gql`
    query StationUncategorizedEntries($id: Int!) {
  station(id: $id) {
    id
    nonCategorisedEntries {
      ...ListEntryFields
    }
  }
}
    ${ListEntryFieldsFragmentDoc}`;

/**
 * __useStationUncategorizedEntriesQuery__
 *
 * To run a query within a React component, call `useStationUncategorizedEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationUncategorizedEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationUncategorizedEntriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStationUncategorizedEntriesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StationUncategorizedEntriesQuery, StationUncategorizedEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<StationUncategorizedEntriesQuery, StationUncategorizedEntriesQueryVariables>(StationUncategorizedEntriesDocument, baseOptions);
      }
export function useStationUncategorizedEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StationUncategorizedEntriesQuery, StationUncategorizedEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StationUncategorizedEntriesQuery, StationUncategorizedEntriesQueryVariables>(StationUncategorizedEntriesDocument, baseOptions);
        }
export type StationUncategorizedEntriesQueryHookResult = ReturnType<typeof useStationUncategorizedEntriesQuery>;
export type StationUncategorizedEntriesLazyQueryHookResult = ReturnType<typeof useStationUncategorizedEntriesLazyQuery>;
export type StationUncategorizedEntriesQueryResult = ApolloReactCommon.QueryResult<StationUncategorizedEntriesQuery, StationUncategorizedEntriesQueryVariables>;
export const StationCategorizedEntriesDocument = gql`
    query StationCategorizedEntries($id: Int!) {
  station(id: $id) {
    id
    categories {
      id
      fcolor
      bcolor
      index
      title
      dingaloes {
        id
        categoryId
        isBorrowed
        entry {
          ...ListEntryFields
          hasUnpublishedActionables
        }
        altTitle
      }
    }
  }
}
    ${ListEntryFieldsFragmentDoc}`;

/**
 * __useStationCategorizedEntriesQuery__
 *
 * To run a query within a React component, call `useStationCategorizedEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationCategorizedEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationCategorizedEntriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStationCategorizedEntriesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StationCategorizedEntriesQuery, StationCategorizedEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<StationCategorizedEntriesQuery, StationCategorizedEntriesQueryVariables>(StationCategorizedEntriesDocument, baseOptions);
      }
export function useStationCategorizedEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StationCategorizedEntriesQuery, StationCategorizedEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StationCategorizedEntriesQuery, StationCategorizedEntriesQueryVariables>(StationCategorizedEntriesDocument, baseOptions);
        }
export type StationCategorizedEntriesQueryHookResult = ReturnType<typeof useStationCategorizedEntriesQuery>;
export type StationCategorizedEntriesLazyQueryHookResult = ReturnType<typeof useStationCategorizedEntriesLazyQuery>;
export type StationCategorizedEntriesQueryResult = ApolloReactCommon.QueryResult<StationCategorizedEntriesQuery, StationCategorizedEntriesQueryVariables>;
export const OtherStationCategorizedEntriesDocument = gql`
    query OtherStationCategorizedEntries($id: Int!) {
  station(id: $id) {
    id
    prefix
    title
    categories {
      id
      title
      dingaloes {
        id
        entry {
          ...ListEntryFields
          dingaloTitle
        }
      }
    }
  }
}
    ${ListEntryFieldsFragmentDoc}`;

/**
 * __useOtherStationCategorizedEntriesQuery__
 *
 * To run a query within a React component, call `useOtherStationCategorizedEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherStationCategorizedEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherStationCategorizedEntriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOtherStationCategorizedEntriesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OtherStationCategorizedEntriesQuery, OtherStationCategorizedEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<OtherStationCategorizedEntriesQuery, OtherStationCategorizedEntriesQueryVariables>(OtherStationCategorizedEntriesDocument, baseOptions);
      }
export function useOtherStationCategorizedEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OtherStationCategorizedEntriesQuery, OtherStationCategorizedEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OtherStationCategorizedEntriesQuery, OtherStationCategorizedEntriesQueryVariables>(OtherStationCategorizedEntriesDocument, baseOptions);
        }
export type OtherStationCategorizedEntriesQueryHookResult = ReturnType<typeof useOtherStationCategorizedEntriesQuery>;
export type OtherStationCategorizedEntriesLazyQueryHookResult = ReturnType<typeof useOtherStationCategorizedEntriesLazyQuery>;
export type OtherStationCategorizedEntriesQueryResult = ApolloReactCommon.QueryResult<OtherStationCategorizedEntriesQuery, OtherStationCategorizedEntriesQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($stationId: Int!) {
  createCategory(stationId: $stationId) {
    id
  }
}
    `;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      stationId: // value for 'stationId'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const ReorderCategoryDocument = gql`
    mutation ReorderCategory($dragCategoryId: Int!, $dropCategoryId: Int!) {
  reorderCategory(
    dragCategoryId: $dragCategoryId
    dropCategoryId: $dropCategoryId
  ) {
    id
  }
}
    `;

/**
 * __useReorderCategoryMutation__
 *
 * To run a mutation, you first call `useReorderCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderCategoryMutation, { data, loading, error }] = useReorderCategoryMutation({
 *   variables: {
 *      dragCategoryId: // value for 'dragCategoryId'
 *      dropCategoryId: // value for 'dropCategoryId'
 *   },
 * });
 */
export function useReorderCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReorderCategoryMutation, ReorderCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<ReorderCategoryMutation, ReorderCategoryMutationVariables>(ReorderCategoryDocument, baseOptions);
      }
export type ReorderCategoryMutationHookResult = ReturnType<typeof useReorderCategoryMutation>;
export type ReorderCategoryMutationResult = ApolloReactCommon.MutationResult<ReorderCategoryMutation>;
export type ReorderCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<ReorderCategoryMutation, ReorderCategoryMutationVariables>;
export const CreateEntryDocument = gql`
    mutation CreateEntry($stationId: Int!) {
  createEntry(stationId: $stationId) {
    id
  }
}
    `;

/**
 * __useCreateEntryMutation__
 *
 * To run a mutation, you first call `useCreateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryMutation, { data, loading, error }] = useCreateEntryMutation({
 *   variables: {
 *      stationId: // value for 'stationId'
 *   },
 * });
 */
export function useCreateEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntryMutation, CreateEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntryMutation, CreateEntryMutationVariables>(CreateEntryDocument, baseOptions);
      }
export type CreateEntryMutationHookResult = ReturnType<typeof useCreateEntryMutation>;
export type CreateEntryMutationResult = ApolloReactCommon.MutationResult<CreateEntryMutation>;
export type CreateEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntryMutation, CreateEntryMutationVariables>;
export const namedOperations = {
  Query: {
    UserScreen: 'UserScreen',
    Stations: 'Stations',
    User: 'User',
    Users: 'Users',
    Actionable: 'Actionable',
    EditStation: 'EditStation',
    CategoryEdit: 'CategoryEdit',
    Entry: 'Entry',
    SearchOtherStationEntries: 'SearchOtherStationEntries',
    Station: 'Station',
    StationUncategorizedEntries: 'StationUncategorizedEntries',
    StationCategorizedEntries: 'StationCategorizedEntries',
    OtherStationCategorizedEntries: 'OtherStationCategorizedEntries'
  },
  Mutation: {
    Login: 'Login',
    CreateStation: 'CreateStation',
    UpdateUser: 'UpdateUser',
    CreateUser: 'CreateUser',
    DeleteUser: 'DeleteUser',
    CreateReferenceForFirstDuplicate: 'CreateReferenceForFirstDuplicate',
    CreateActionableAndAttach: 'CreateActionableAndAttach',
    UpdateActionable: 'UpdateActionable',
    DeleteActionable: 'DeleteActionable',
    UpdateStation: 'UpdateStation',
    DeleteStation: 'DeleteStation',
    SortCategoryEntries: 'SortCategoryEntries',
    AttachEntryToCategory: 'AttachEntryToCategory',
    UpdateCategorisedEntryOrder: 'UpdateCategorisedEntryOrder',
    UpdateCategory: 'UpdateCategory',
    DeleteCategory: 'DeleteCategory',
    UpdateEntry: 'UpdateEntry',
    DeleteEntry: 'DeleteEntry',
    UpdateEntryInCategory: 'UpdateEntryInCategory',
    DeleteEntryInCategory: 'DeleteEntryInCategory',
    CreateCategory: 'CreateCategory',
    ReorderCategory: 'ReorderCategory',
    CreateEntry: 'CreateEntry'
  },
  Fragment: {
    SelfFields: 'SelfFields',
    UserEditFields: 'UserEditFields',
    ActionableEditFields: 'ActionableEditFields',
    StationEditFields: 'StationEditFields',
    CategoryEditFields: 'CategoryEditFields',
    EntryEditFields: 'EntryEditFields',
    EntryInCategoryEditFields: 'EntryInCategoryEditFields',
    ListEntryFields: 'ListEntryFields'
  }
}