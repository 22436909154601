import React from "react"
import ReactDOM from "react-dom"
import { App } from "./App"
import "antd/dist/antd.css"
import dayjs from "dayjs"
import Duration from "dayjs/plugin/duration"
import CustomParseFormat from "dayjs/plugin/customParseFormat"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

// Initialise Dayjs plugins globally
dayjs.extend(Duration)
dayjs.extend(CustomParseFormat)
dayjs.extend(LocalizedFormat)

ReactDOM.render(React.createElement(App), document.getElementById("root"))
