import styled from "styled-components"

/**
 * Title part of Modal w/ additional css fields for custom ui
 */
export const ModalDetails = styled.div`
  font-size: 0.95em;
  color: slategray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  > span {
    margin-right: 1em;
    white-space: nowrap;
    > u {
      margin-right: 3px;
    }
  }
`
