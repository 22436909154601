import { Button, Input, Select } from "antd"
import styled, { css } from "styled-components"

export const Wrapper = styled.div<{ $beIsValid: boolean }>`
  margin: 20px 0;
  padding: 4px 6px;
  background-color: ${({ $beIsValid }) => ($beIsValid ? "HONEYDEW" : "MISTYROSE")};
  border: 1px dashed blue;
`
export const Header = styled.div`
  display: flex;
  align-items: baseline;
`

export const SelectStation = styled(Select).attrs({ size: "small" })`
  width: 400px;
`

export const StationCategories = styled.div`
  min-height: 40px;
  max-height: 180px;
  margin: 0 0;
  font-size: 0.95em;
  overflow-x: clip;
  overflow-y: auto;
`

export const StationCategory = styled.li<{ $isTarget?: boolean }>`
  border-style: ridge;
  display: block;
  padding: 2px 4px;
  ${({ $isTarget }) => $isTarget === true && "background: lightgoldenrodyellow;"}

  > a {
    margin-right: 0.5em;
  }
`

export const Footer = styled.div<{ isInactive?: boolean }>`
  margin-top: 3em;
  text-align: right;
  border-top: 1px solid lightgray;
  padding-top: 4px;

  ${({ isInactive }) =>
    isInactive &&
    css`
      opacity: 0.2;
    `}
`

export const RowFlexed = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 6px;
`

export const SelectKind = styled(Select).attrs({ size: "small" })`
  width: 260px;
`

export const ButtonDetachFromCategory = styled(Button).attrs({ type: "dashed" })`
  margin-left: 6px;
`
