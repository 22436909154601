import styled, { css } from "styled-components"

const commonCss = css`
  text-align: right;
  min-width: 0.6em; /* so that small icons eg. (N) do not become lost */
`
const clickableCss = css`
  cursor: pointer;
  color: #5b57ff;
  transition: background-color 200ms ease 0s;
  &:hover {
    background: cyan;
  }
`
export const Wrapper = styled("div")`
  margin: 2px 0;
`
export const Empty = styled("span")`
  ${commonCss}
  border:1px solid orange;
  width: 40px;
  text-align: center;
`
export const FixedPhone = styled("span")`
  ${commonCss}
  color: forestgreen;
`
export const PreFixedPhone = styled("span")`
  font-size: 0.82em;
`
export const MobileOrPager = styled("span")`
  ${commonCss}
  ${clickableCss}
`
export const PhoneGeneral = styled("span")`
  ${commonCss}
`
export const LshInfo = styled("span")`
  ${commonCss}
  ${clickableCss}
`
export const Vaktaskra = styled("span")`
  ${commonCss}
  ${clickableCss}
`
export const Prefix = styled("span")`
  color: slategray;
  font-size: 0.94em;
  margin-right: 5px;
`
export const UnspecifiedAction = styled("span")`
  ${commonCss}
  ${clickableCss}
`
export const Link = styled("span")`
  display: inline-block;
  text-align: center;
  background: lightgray;
  border-radius: 5px;
  width: 26px;
  color: slateblue;
  padding: 0;
  transition: color 200ms;
  &:hover {
    background-color: cyan;
  }
`
