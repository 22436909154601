import styled from "styled-components"

export const WrapperCategory = styled.div`
  border: 1px solid LIGHTSTEELBLUE;
  margin: 0 0.5em;
  margin-bottom: 1.5em;
  break-inside: avoid;

  &.dragTarget {
    &.isOver {
      border-style: ridge;
      border-width: thick;
      > * {
        opacity: 0.3;
      }
    }
    &.isnotOver {
      border-style: dashed;
    }
  }
  &.dragSource {
    background: white;
    overflow: hidden;
  }
`
