import styled from "styled-components"

export const LoginForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px;
  height: 100vh;
`

export const Area = styled.div`
  display: flex;
`

export const InputFields = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: row;

  > label {
    width: 12em;
    margin-right: 1em;
  }

  > input {
    width: 18em;
  }
`

export const Submit = styled.div`
  align-self: flex-end;
`

export const Message = styled.div`
  background: ORANGE;
  color: white;
  font-size: 1.4em;
  font-weight: bold;
`

export const Title = styled.div`
  font-size: 3em;
  color: STEELBLUE;
`

export const SubTitle = styled.div`
  font-size: 1.25em;
  color: SLATEGRAY;
`
