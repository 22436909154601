import { ApolloProvider } from "@apollo/client"
import { AuthProvider } from "@app/lib/auth"
import { ConfigProvider as AntdConfigProvider } from "antd"
import isIS from "antd/es/locale/is_IS"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Route, Router, Switch } from "react-router-dom"
import { client } from "./apollo"
import history from "./browserHistory"
import { Login } from "./routes/guestScreen/Login/Login"
import PrivateRoute from "./routes/PrivateRoute"
import { FrontPage } from "./routes/userScreen/FrontPage/FrontPage"

export const App = () => (
  <ApolloProvider client={client}>
    <DndProvider backend={HTML5Backend}>
      <AntdConfigProvider locale={isIS}>
        <Router history={history}>
          <AuthProvider>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute path="/" component={FrontPage} />
            </Switch>
          </AuthProvider>
        </Router>
      </AntdConfigProvider>
    </DndProvider>
  </ApolloProvider>
)
