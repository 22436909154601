import { SearchOtherStationEntriesQuery } from "@app/generated"
import { EditEntryWrapper } from "@app/lib/EditEntryWrapper/EditEntryWrapper"
import { dndTypes } from "@app/routes/userScreen/station/lib/dndTypes"
import React, { FC } from "react"
import { useDrag } from "react-dnd"
import { IDragEntry } from "../lib/IDragEntry"
import { useHistory } from "react-router-dom"

type Props = {
  entry: SearchOtherStationEntriesQuery["entries"][0]
}

export const SearchOtherStationEntry: FC<Props> = ({ entry }) => {
  const { push } = useHistory()
  const [_, dragEntryRef] = useDrag(() => ({ item: { entryId: entry.id, type: dndTypes.UNCATEGORISEDENTRY } as IDragEntry }))

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <u style={{ marginRight: "1em" }}>{entry.station.title}</u>
      <EditEntryWrapper key={entry.id} handleClick={() => push(`/editStation/${entry.station.id}/${entry.id}/,`)} $ref={dragEntryRef} entry={entry} />
    </div>
  )
}
