import { ListEntryFieldsFragment } from "@app/generated"
import React, { FC } from "react"
import { Link, Prefix, Wrapper, Actionables } from "./EditEntryWrapper.styles"
import { ViewedActionable } from "@infopage/common/lib/ViewedActionable/ViewedActionable"

export type Props = {
  entry: ListEntryFieldsFragment
  dingaloId?: number
  handleClick: () => void
  $ref: any
  $className?: string
  isBorrowed?: boolean
  isNotValidated?: boolean
}

export const EditEntryWrapper: FC<Props> = ({
  isBorrowed,
  isNotValidated = false,
  entry: { actionables, title, prefix, dingaloTitle },
  handleClick,
  $ref,
  $className
}) => (
  <Wrapper ref={$ref} className={$className} $isNotValidated={isNotValidated}>
    {prefix !== null && <Prefix>{prefix}</Prefix>}
    <Link onClick={handleClick} $isBorrowed={isBorrowed}>
      {dingaloTitle}
    </Link>

    <Actionables>
      {actionables
        .filter(({ value }) => value !== null)
        .map(({ id, kind, value, prefix }) => (
          <ViewedActionable key={id} kindId={kind.id} value={value ?? "???"} prefix={prefix} />
        ))}
    </Actionables>
  </Wrapper>
)
