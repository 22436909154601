import { useMutation } from "@apollo/client"
import { Button, Col, Modal, Row } from "antd"
import { UpdateCategoryDocument, UpdateCategoryMutationVariables, useCategoryEditQuery } from "@app/generated"
import { Loadable } from "@app/lib/Loadable/Loadable"
import React from "react"
import { Label } from "@app/lib/Label/Label"
import { StatefulInput } from "@app/lib/StatefulInput"

export const CategoryEditor = Loadable(useCategoryEditQuery)<{
  id: number
  closeCategoryEditor: () => void
}>(
  ({
    id: categoryId,
    data: {
      category: { title, fcolor, bcolor }
    },
    closeCategoryEditor
  }) => {
    const [_updateCategory] = useMutation(UpdateCategoryDocument)
    const updateCategory = (input: UpdateCategoryMutationVariables["input"]) => _updateCategory({ variables: { id: categoryId, input } })

    return (
      <Modal visible maskClosable closable onCancel={closeCategoryEditor} footer={<Button onClick={closeCategoryEditor} type="primary" children={"áfram"} />}>
        <div>
          <Row align="middle" gutter={[2, 8]}>
            <Col span={24}>
              <Label title="titill">
                <StatefulInput initialValue={title} handleSubmit={(title) => updateCategory({ title })} />
              </Label>
            </Col>
          </Row>

          <Row align="middle" gutter={[2, 8]}>
            <Col span={12}>
              <Label
                title="forlitur"
                popoverInfo={
                  <div>
                    Litakóðar geta verið nafn litar (d: "red"), RGB gildi (d: "rgb(255,0,0") eða 'hex' gildi (d: "#ff0000").
                    <a target="_new" href="https://htmlcolorcodes.com">
                      Sjá litakort
                    </a>
                  </div>
                }
              >
                <StatefulInput initialValue={fcolor} handleSubmit={(fcolor) => updateCategory({ fcolor })} />
              </Label>
            </Col>
            <Col span={12}>
              <Label title="baklitur">
                <StatefulInput initialValue={bcolor} handleSubmit={(bcolor) => updateCategory({ bcolor })} />
              </Label>
            </Col>
          </Row>
        </div>
      </Modal>
    )
  },
  ({ id }) => ({ id })
)
