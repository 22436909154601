import { PlusCircleTwoTone } from "@ant-design/icons"
import { useMutation } from "@apollo/client"
import { CreateEntryDocument, namedOperations, useStationUncategorizedEntriesQuery } from "@app/generated"
import { PopoverIcon } from "@app/lib/PopoverIcon"
import { SetEditEntry } from "@app/routes/userScreen/station/lib/SetEditEntry"
import { Wrapper } from "@app/styles"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Button, Empty } from "antd"
import React from "react"
import { UnCategorisedEntry } from "../EntryUnCategorized/EntryUnCategorized"
import { Entries } from "./UnCategorisedEntries.styles"

type Props = {
  id: number
  setEditEntry: SetEditEntry
  canSelfEdit: boolean
}

const {
  Query: { StationUncategorizedEntries }
} = namedOperations

export const UnCategorisedEntries = Loadable(useStationUncategorizedEntriesQuery)<Props>(
  ({
    data: {
      station: { id: stationId, nonCategorisedEntries }
    },
    canSelfEdit,
    setEditEntry
  }) => {
    const [createEntry] = useMutation(CreateEntryDocument, { variables: { stationId }, refetchQueries: [StationUncategorizedEntries] })

    return (
      <Wrapper>
        <div className="subTitle">
          <PopoverIcon
            title="óflokkaðir"
            content="Óflokkaðir hlekkir eru óbirtir í Heilsugátt. Ef starfsstöð er birt má nota óflokkaða hlekki fyrir aðrar starfsstöðvar til notkunar - þar sem starfsstöð á hlekk ber hún ábyrgð á að uppfæra innihald (s.s. símanúmer eða url) og stöð sem birtir hlekk treystir á að innihald er alltaf rétt."
          />
          {canSelfEdit && (
            <Button size="small" icon={<PlusCircleTwoTone />} onClick={() => createEntry()}>
              nýr hlekkur
            </Button>
          )}
        </div>
        <div className="content">
          {nonCategorisedEntries.length > 0 ? (
            <Entries>
              {nonCategorisedEntries.map((entry) => (
                <UnCategorisedEntry key={entry.id} entry={entry} setEditEntry={() => setEditEntry(entry.id)} />
              ))}
            </Entries>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null} />
          )}
        </div>
      </Wrapper>
    )
  },
  ({ id }) => ({ id: Number(id) })
)
