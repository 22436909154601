import { PlusOutlined } from "@ant-design/icons"
import { useMutation } from "@apollo/client"
import { CreateUserDocument, namedOperations, useUsersQuery, GlobalRoleId } from "@app/generated"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Button, Input, Table, Tag } from "antd"
import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { User } from "../UserEditor/UserEditor"
import { Station, Stations, Updated } from "./Users.styles"

const Users = Loadable(useUsersQuery)<RouteComponentProps<{ userId: string }>>(
  ({
    data: {
      users,
      self: { isGlobalModerator }
    }
  }) => {
    const [newUserSsn, setNewUserSsn] = useState("")
    const [editUserId, setEditUserId] = useState<null | number>(null)
    const [_createUser] = useMutation(CreateUserDocument, {
      variables: { ssn: newUserSsn },
      refetchQueries: [namedOperations.Query.Users],
      awaitRefetchQueries: true,
      onCompleted: ({ createUser: { id } }) => setEditUserId(id)
    })
    const createUser = () => _createUser()

    const parsedUsers = users.map((user) => {
      const { latestLoginAt } = user
      const latestLoginHours = latestLoginAt === null ? Number.MAX_VALUE : Math.floor((new Date().valueOf() - new Date(latestLoginAt!).valueOf()) / 60 / 60e3)
      return { ...user, latestLoginHours }
    })

    return (
      <>
        {isGlobalModerator && (
          <div style={{ float: "right" }}>
            <Input type="text" value={newUserSsn} placeholder="kennitala" style={{ width: "8em" }} onChange={(e) => setNewUserSsn(e.target.value)} />
            <Button icon={<PlusOutlined />} onClick={createUser} />
          </div>
        )}

        <Table
          rowKey="id"
          showHeader={false}
          dataSource={parsedUsers}
          columns={[
            {
              key: "latestLogin",
              title: "Seinasta innskráning",
              render: (_, { latestLoginHours, latestLoginAt }) => (
                <Updated hours={latestLoginHours}>{latestLoginHours < Number.MAX_VALUE ? latestLoginAt!.split("T")[0] : ""}</Updated>
              )
            },
            {
              key: "name",
              title: "Nafn",
              width: "340px",
              render: (_, { id, name, ssn, globalRole }) => (
                <>
                  <span>
                    {isGlobalModerator ? (
                      <Button type="link" title={ssn} onClick={() => setEditUserId(id)}>
                        {name}
                      </Button>
                    ) : (
                      <span>{name}</span>
                    )}
                  </span>
                  {globalRole.id !== GlobalRoleId.Generic && (
                    <Tag color="red" style={{ marginLeft: "8px" }}>
                      {globalRole.label}
                    </Tag>
                  )}
                </>
              )
            },
            {
              key: "editorForStations",
              title: "Starfsstöðvar",
              render: (_, { editorForStations }) => (
                <Stations>
                  {editorForStations.map(({ id, title }) => (
                    <Station key={id} to={`/editStation/${id}`}>
                      {title}
                    </Station>
                  ))}
                </Stations>
              )
            }
          ]}
          pagination={false}
        />

        {editUserId !== null && <User id={editUserId} handleCancel={() => setEditUserId(null)} />}
      </>
    )
  }
)

export default Users
