import { EditEntryWrapper } from "@app/lib/EditEntryWrapper/EditEntryWrapper"
import { SetEditEntry } from "@app/routes/userScreen/station/lib/SetEditEntry"
import { dndTypes } from "@app/routes/userScreen/station/lib/dndTypes"
import { StationCategorizedEntriesQuery } from "@app/generated"
import React, { FC, useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { IDragEntry } from "../lib/IDragEntry"

export const EntryCategorized: FC<{
  entryInCategory: StationCategorizedEntriesQuery["station"]["categories"][0]["dingaloes"][0]
  categoryId: number
  reorderEntryInCategory: (categoryId: number, dragEntryId: number, dropEntryId: number) => void
  setEditEntry: SetEditEntry
}> = ({
  entryInCategory: {
    id: dingaloId,
    categoryId,
    isBorrowed,
    entry,
    entry: { id: entryId }
  },
  setEditEntry: _setEditEntry,
  reorderEntryInCategory
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const setEditEntry = () => _setEditEntry(entryId, dingaloId)

  // DRAG Entry to 1. reorder within Category 2. move between Categories
  const [_, dragEntryRef] = useDrag(() => ({ item: { entryId, categoryId, type: dndTypes.CATEGORISEDENTRY } as IDragEntry }))

  // DROP Entry
  const [dropClass, dropEntryRef] = useDrop(() => ({
    accept: [dndTypes.CATEGORISEDENTRY],
    canDrop: (item: any) => {
      // never drop Entry onto self
      if (item.entryId === entryId) return false

      // only drop on Entries within same Category
      if (item.categoryId !== categoryId) return false
      return true
    },
    drop: (item, monitor) => {
      monitor.canDrop() && reorderEntryInCategory(categoryId, item.entryId, entryId)
    },
    collect: (monitor) => {
      const item = monitor.getItem()
      if (item === null) return undefined
      if (monitor.canDrop() === false) return undefined
      // dropClass string
      return `canDrop ${monitor.isOver() ? "isOver" : ""}`
    }
  }))

  dragEntryRef(dropEntryRef(ref))

  return (
    <EditEntryWrapper
      $className={dropClass}
      $ref={ref}
      entry={entry}
      isNotValidated={entry.hasUnpublishedActionables}
      dingaloId={dingaloId}
      isBorrowed={isBorrowed}
      handleClick={setEditEntry}
    />
  )
}
