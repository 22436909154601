import { Link } from "react-router-dom"
import styled from "styled-components"

export const Updated = styled.div<{ hours: number }>`
  padding-right: 1em;
  white-space: nowrap;
  color: ${(op) => (op.hours < 24 ? "ORANGE" : op.hours < 3 * 24 ? "SLATEGRAY" : "LIGHTGRAY")};
`

export const Stations = styled.div`
  font-size: 0.88em;
  width: 100%;
`

export const Station = styled(Link)`
  display: inline-block;
  background: beige;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0 0.3em;
  margin: 0.2em 0.3em;
  white-space: nowrap;
`
