import { Input } from "antd"
import { FC, useEffect, useState } from "react"

export const StatefulTextarea: FC<{
  initialValue: string | null
  disabled?: boolean
  handleSubmit: (value: string | null) => void
}> = ({ handleSubmit: _handleSubmit, initialValue, disabled }) => {
  const [value, setValue] = useState<string | null>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleSubmit = () => _handleSubmit(value === "" ? null : value)

  return <Input.TextArea value={value || ""} disabled={disabled} onChange={({ target }) => setValue(target.value)} onBlur={() => handleSubmit()} />
}
