import { useMutation } from "@apollo/client"
import {
  ActionableKindId,
  DeleteActionableDocument,
  UpdateActionableMutationResult,
  UpdateActionableMutationVariables,
  useActionableQuery,
  useCreateReferenceForFirstDuplicateMutation,
  useUpdateActionableMutation
} from "@app/generated"
import { Label } from "@app/lib/Label/Label"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { StatefulInput } from "@app/lib/StatefulInput"
import { Button, Col, Modal, Popconfirm, Popover, Row, Select } from "antd"
import React from "react"
import { Errors, MatchedEntryWrapper, SelectKind } from "./ActionableEditor.styles"
import { MatchedEntry } from "./MatchedEntry/MatchedEntry"

const { Option } = Select

export const ActionableEditor = Loadable(useActionableQuery)<{
  id: number | null
  canUserEdit: boolean
  closeActionableEditor: () => void
}>(
  ({
    canUserEdit,
    data: {
      actionable: { id: actionableId, prefix, value, kind },
      actionableKinds
    },
    closeActionableEditor
  }) => {
    // HACK this is lazy implementation
    const [updatedActionable, setUpdatedActionable] = React.useState<null | NonNullable<UpdateActionableMutationResult["data"]>["updateActionable"]>(null)

    const [_deleteActionable] = useMutation(DeleteActionableDocument, { onCompleted: closeActionableEditor })
    const deleteActionable = () => _deleteActionable({ variables: { id: actionableId } })

    const [_createReferenceForFirstDuplicate] = useCreateReferenceForFirstDuplicateMutation({
      variables: { id: actionableId },
      onCompleted: closeActionableEditor
    })

    const [_updateActionable] = useUpdateActionableMutation({
      onCompleted: ({ updateActionable }) => {
        if (updateActionable.actionable !== undefined) setUpdatedActionable(updateActionable)
      }
    })
    const updateActionable = (input: UpdateActionableMutationVariables["input"]) => _updateActionable({ variables: { id: actionableId, input } })

    return (
      <Modal
        destroyOnClose
        centered
        visible
        width="800px"
        closable={true}
        onCancel={closeActionableEditor}
        footer={
          <div>
            <Button children="Loka" onClick={closeActionableEditor} />
            <Popconfirm title="Eyða" onConfirm={() => deleteActionable()}>
              <Button danger disabled={!canUserEdit} children="Eyða" />
            </Popconfirm>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation()
                closeActionableEditor()
              }}
              disabled={!canUserEdit}
              children="Vista"
            />
          </div>
        }
      >
        <div>
          <Row align="middle" gutter={[2, 4]}>
            <Col span={12}>
              <Label title="tegund">
                <SelectKind
                  disabled={kind.id === ActionableKindId.Linktoactionable}
                  value={kind!.id}
                  onChange={(kindId) => updateActionable({ kindId: kindId as ActionableKindId })}
                >
                  {actionableKinds.map(({ id, label, disabled }) => (
                    <Option key={id} value={id}>
                      {label}
                    </Option>
                  ))}
                </SelectKind>
              </Label>
            </Col>
            <Col span={5}>
              <Label
                title="forskeyti"
                popoverInfo="Örstutt forskeyti sem má nota til að aðgreina tagg frá öðrum t.d. 'Hrb' eða 'Fv' fyrir vaktir sem eru virkar í ákveðnu húsi eingöngu t.d. svæfing. Er oftast tómt."
              >
                <StatefulInput disabled={!canUserEdit} initialValue={prefix} handleSubmit={(prefix) => updateActionable({ prefix })} />
              </Label>
            </Col>
          </Row>

          {kind !== null && (
            <>
              <Row align="middle" gutter={[2, 4]}>
                <Col span={24}>
                  <Label title={`${kind.label}: ${kind.description}`}>
                    <StatefulInput
                      // only autofocus if editing newly created Actionable
                      autoFocus={updatedActionable === null}
                      initialValue={value}
                      successFul={updatedActionable?.validationError === null}
                      disabled={!canUserEdit || kind.id === ActionableKindId.Linktoactionable}
                      handleSubmit={(value) => updateActionable({ value })}
                    />
                  </Label>
                </Col>
              </Row>

              {updatedActionable !== null && (
                <Row align="middle" gutter={[2, 4]}>
                  <Col span={24}>
                    {updatedActionable.validationError !== null && <Errors>{updatedActionable.validationError}</Errors>}
                    {updatedActionable.matchedEntry !== null && (
                      <MatchedEntryWrapper>
                        <MatchedEntry entry={updatedActionable.matchedEntry} />
                        <Popover
                          trigger="hover"
                          content="Við viljum koma í veg fyrir tvískráningar á töggum þar sem það gerir allt viðhald flóknara. Í stað þess að gera tvískráð Tagg er hægt að gera ´vísun' í upprunalegt Tagg sem fannst og birtist þá eins gagnvart notanda en er viðhaldið af eiganda Hlekks."
                        >
                          <Button onClick={() => _createReferenceForFirstDuplicate()}>gera vísun</Button>
                        </Popover>
                      </MatchedEntryWrapper>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </Modal>
    )
  },
  ({ id }) => ({ id: id! }),
  ({ id }) => ({ skip: id === null })
)
