import { PlusOutlined } from "@ant-design/icons"
import { useMutation } from "@apollo/client"
import { CreateStationDocument, useStationsQuery } from "@app/generated"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Table, Tag } from "antd"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import { CreateStation, Prefix, Updated } from "./Stations.styles"
import dayjs from "dayjs"

export const ListAllStations = Loadable(useStationsQuery)<{}>(
  ({
    data: {
      stations,
      self: { isGlobalAdministrator }
    }
  }) => {
    const { push } = useHistory()

    const [_createStation] = useMutation(CreateStationDocument, {
      onCompleted: ({ createStation: { id } }) => {
        push(`/editStation/${id}`)
      }
    })

    const parsedStations = stations.map(({ id, prefix, title, isPublished, updatedAt, userModerator, canSelfEdit }) => {
      const lastUpdated = dayjs(updatedAt)
      const latestUpdatedHours = updatedAt === null ? Number.MAX_VALUE : Math.floor((new Date().valueOf() - new Date(updatedAt).valueOf()) / 60 / 60e3)

      return { id, prefix, title, isPublished, updatedAt, lastUpdated, latestUpdatedHours, userModerator, canSelfEdit }
    })

    return (
      <div>
        {isGlobalAdministrator && <CreateStation onClick={() => _createStation()} icon={<PlusOutlined />} />}
        <Table
          showHeader={false}
          rowKey="id"
          dataSource={parsedStations}
          onRow={({ id }) => ({ onClick: () => push(`/editStation/${id}`) })}
          pagination={false}
          columns={[
            {
              key: "prefix",
              title: "Forskeyti",
              width: "50px",
              render: (_, { prefix }) => <Prefix>{prefix || "---"}</Prefix>
            },
            {
              key: "title",
              title: "Heiti",
              width: "500px",
              render: (_, { id, title, isPublished, canSelfEdit }) => (
                <>
                  {!isPublished && <Tag color="red">óbirt</Tag>}
                  <Link to={`/editStation/${id}`} style={{ fontWeight: canSelfEdit ? "bold" : "normal", fontSize: canSelfEdit ? "1.2em" : "1.0em" }}>
                    {title}
                  </Link>
                </>
              )
            },
            {
              key: "moderator",
              title: "Stjórnandi",
              render: (_, { userModerator }) => <span>{userModerator.name}</span>
            },
            {
              title: "Seinast uppfært",
              render: (_, { latestUpdatedHours, updatedAt, lastUpdated }) => (
                <Updated hours={latestUpdatedHours} title={`${lastUpdated}`}>
                  {latestUpdatedHours < Number.MAX_VALUE ? updatedAt.split("T")[0] : ""}
                </Updated>
              )
            }
          ]}
        />
      </div>
    )
  }
)
