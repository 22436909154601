import styled from "styled-components"

/**
 * Purpose to attract attention if >0.
 */

export const NumberIndicator = styled.span<{ $count: number }>`
  color: ${({ $count }) => ($count ? "red" : "slategray")};
  font-weight: bold;
`
