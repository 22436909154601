import React from "react"
import { useMutation } from "@apollo/client"
import { UpdateStationDocument, UpdateStationMutationVariables, useEditStationQuery } from "@app/generated"
import { Loadable } from "@app/lib/Loadable/Loadable"
import { Button, Col, Modal, Row, Select, Switch } from "antd"
import { Label } from "@app/lib/Label/Label"
import { StatefulInput } from "@app/lib/StatefulInput"
import { StatefulTextarea } from "@app/lib/StatefulTextarea"

type Props = {
  id: number
  closeEditStation: () => void
}

export const StationEditor = Loadable(useEditStationQuery)<Props>(
  ({
    id: stationId,
    closeEditStation,
    data: {
      station: {
        canSelfEdit,
        editors,
        isPublished,
        orgsWantData,
        userModeratorId,
        prefix,
        title,
        description,
        showEntryDescription: { id: showEntryDescriptionId },
        lshDepartments
      },
      allUsers,
      showEntryDescriptions
    }
  }) => {
    const [_updateStation] = useMutation(UpdateStationDocument)
    const updateStation = (input: UpdateStationMutationVariables["input"]) => _updateStation({ variables: { id: stationId, input } })

    return (
      <Modal
        width={800}
        visible
        maskClosable={true}
        closable={true}
        onCancel={closeEditStation}
        footer={
          <>
            <Button onClick={closeEditStation}>Loka</Button>
            <Button onClick={() => closeEditStation()} type="primary" children={"Vista"} />
          </>
        }
      >
        <div>
          <Row align="middle" gutter={[2, 8]}>
            <Col span={4}>
              <Label title="forskeyti" disabled={!canSelfEdit} popoverInfo="t.d. kóði deildar, auðveldar aðgreiningu í fellivalmynd">
                <StatefulInput initialValue={prefix} handleSubmit={(prefix) => updateStation({ prefix })} />
              </Label>
            </Col>
            <Col span={16}>
              <Label title="forskeyti" disabled={!canSelfEdit}>
                <StatefulInput initialValue={title} handleSubmit={(title) => updateStation({ title })} />
              </Label>
            </Col>
            <Col flex={4}>
              <Switch
                title={orgsWantData.join("|")}
                checked={isPublished}
                checkedChildren="birt"
                unCheckedChildren="óbirt"
                onClick={(isPublished) => updateStation({ isPublished })}
              />
            </Col>
          </Row>

          <Row align="top" gutter={[4, 8]}>
            <Col span={8}>
              <Label title="lýsing, sýnileiki" popoverInfo="lýsingar taka pláss, því getur ritstjóri ákveðið hvort/hvernig þær eru birtar">
                <Select
                  style={{ width: "100%" }}
                  value={showEntryDescriptionId}
                  onChange={(showEntryDescriptionId) => updateStation({ showEntryDescriptionId })}
                >
                  {showEntryDescriptions.map(({ id, label }) => (
                    <Select.Option key={id} value={id}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Label>
            </Col>
            <Col span={16}>
              <Label title="lýsing í haus (efst)" disabled={!canSelfEdit}>
                <StatefulTextarea initialValue={description} handleSubmit={(description) => updateStation({ description })} />
              </Label>
            </Col>
          </Row>

          <Row align="middle" gutter={[4, 8]}>
            <Col span={8}>
              <Label
                title="aðalritstjóri"
                disabled={!canSelfEdit}
                popoverInfo="aðalritstjóri hefur einn vald (ásamt aðgangsstjórum) til að bæta við eða taka út ritstjóra fyrir starfsstöð"
              >
                <Select style={{ width: "100%" }} value={userModeratorId} onChange={(userModeratorId) => updateStation({ userModeratorId })}>
                  {allUsers.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Label>
            </Col>
            <Col span={16}>
              <Label title="ritstjórar" disabled={!canSelfEdit}>
                <Select
                  style={{ width: "100%" }}
                  mode="multiple"
                  maxTagCount={5}
                  value={editors.map(({ id }) => id)}
                  onChange={(editorIds) => updateStation({ editorIds })}
                  showSearch
                >
                  {allUsers.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Label>
            </Col>
          </Row>

          <Row align="middle" gutter={[4, 2]}>
            <Col span={24}>
              <Label
                title="deildir"
                popoverInfo="tenging LSH innskráningardeildar við starfsstöð (sjá ítarlegri leiðbeiningar). Ef fleiri en ein deild, aðskiljið þá með kommu"
              >
                <Select
                  style={{ width: "220px" }}
                  mode="tags"
                  value={Array.isArray(lshDepartments) ? lshDepartments : []}
                  onChange={(lshDepartments) => updateStation({ lshDepartments: lshDepartments.map((v) => Number(v)) })}
                  showSearch
                >
                  {(lshDepartments || []).map((lshDepartment) => (
                    <Select.Option key={lshDepartment} value={String(lshDepartment)}>
                      {lshDepartment}
                    </Select.Option>
                  ))}
                </Select>
              </Label>
            </Col>
          </Row>
        </div>
      </Modal>
    )
  },
  ({ id }) => ({ id })
)
