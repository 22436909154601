import styled from "styled-components"

export const CategoryHeader = styled.div<{ bcolor?: string; fcolor?: string }>`
  background: ${(op) => (op.bcolor ? op.bcolor : "transparent")};
  border-bottom: 1px solid lightgray;
  padding: 0.1em 0.1em;
  display: flex;

  > .title {
    font-size: 1.1em;
    font-weight: bold;
    color: ${({ fcolor }) => (fcolor ? fcolor : "inherit")};
    flex-grow: 1;
  }
  > .right {
    white-space: nowrap;
  }
`
