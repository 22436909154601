import { Button } from "antd"
import styled from "styled-components"

export const CreateStation = styled(Button)`
  float: right;
`

export const Updated = styled.span<{ hours: number }>`
  padding-right: 1em;
  white-space: nowrap;
  color: ${(op) => (op.hours < 24 ? "ORANGE" : op.hours < 3 * 24 ? "SLATEGRAY" : "LIGHTGRAY")};
`

export const Prefix = styled.div`
  color: slategray;
  font-size: 0.9em;
  margin-right: 8px;
`
