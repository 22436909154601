import { IconInfoHover } from "@app/styles/IconInfoHover.styles"
import { Popover } from "antd"
import React, { FC } from "react"
import { Wrapper } from "./Label.styles"

export const Label: FC<{
  title: string
  placeholder?: string
  disabled?: boolean
  popoverInfo?: string | React.ReactNode
}> = ({ title, popoverInfo, disabled, children }) => (
  <Wrapper>
    <div>
      <span>{title}</span>
      {popoverInfo !== undefined && (
        <Popover content={<span>{popoverInfo}</span>}>
          <IconInfoHover />
        </Popover>
      )}
    </div>
    <div>{React.Children.map(children, (child) => (React.isValidElement(child) ? React.cloneElement(child, { disabled }) : child))}</div>
  </Wrapper>
)
